import React from 'react'

function HeaderTitle({title}) {
  return (
    <div className="bg-header_bg py-6 lg:py-12">

    <div className=" max-w-screen-xl mx-auto"><text className="text-left text-2xl px-2 lg:text-5xl flex font-semibold text-white">{title}</text></div>
    </div>
  )
}

export default HeaderTitle