import {includes} from "draft-js/lib/DefaultDraftBlockRenderMap";
import React,{useState} from 'react'

import {Text} from '../atoms'

function Footer() {


  const [sub, setSub] = useState('')

  const handleSub = () => {
    if (sub.includes('@')) {
      setSub('')
    } else {
      alert('Enter valid email address')
    }
    
  }
  return (
    <div className="bg-gray-700">
    <div className=" lg:flex max-w-screen-xl mx-auto py-10">

   
    <div className="flex flex-col py-3 md:w-10/12 lg:w-4/12 p-5">
      <Text Style="text-2xl font-semibold text-left text-gray-200 " text="Subscribe"/>
      <Text Style="text-lg leading-6 text-gray-300 py-3 font-semibold text-left" text="Sign Up To Receive Special Offers And Updates" />
      <div className="my-2  flex items-center">
        <input value={sub} onChange={(e)=>setSub(e.target.value)}  className="px-1 border rounded-sm py-2 text-gray-800 font-semibold bg-gray-100 outline-none" placeholder="your@email.com" />
        <text onClick={()=>handleSub()} className="px-2 border-2 border-red-500 cursor-pointer rounded-sm py-2 text-sm text-gray-200 bg-red-500 font-semibold mx-2">Subscribe</text>
        </div>
        <text className="text-xs text-gray-300 text-left">Your data will not be shared with others and you can unsubscribe at any time.</text>
      </div>
    <div className="flex flex-col justify-center py-3 md:w-10/12 lg:w-4/12 p-5">
      <Text Style="text-2xl font-semibold text-gray-200 text-left" text="Main Address"/>
      <Text Style="text-xl text-gray-300 font-semibold text-left mt-3 " text="Head Office:" />
        <Text Style="text-xl text-gray-300 font-semibold text-left" text="BombayGifits" />
        <text className="text-md  text-left text-gray-300 mt-2">No 92, Pedariyar Koil Street, 1st Floor, Seven Wells, Chennai – 600 001. Tamil Nadu, India.</text>
        <text className="text-md  text-left text-gray-300">Mob +91 94443 09945</text>
        <text className="text-md  text-left text-gray-300">Mob +91 99405 59443</text>
        <text className="text-md  text-left text-gray-300">Email - bombay.gifts@gmail.com</text>
      </div>
    <div className="flex flex-col justify-center py-3 md:w-10/12 lg:w-4/12 p-5">
        <Text Style="text-xl text-gray-300 text-left font-semibold" text="BombayGifits" />
        <text className="text-md  text-left text-gray-300">No 16, Jai Bhawani Co-Op Housing Society, Ground Floor, Khamba Dev Nagar Sion,
Dharavi – 400 017.

Mumbai, India.</text>
        <text className="text-md  text-left text-gray-300">Mob +91 99679 36383</text>
        <text className="text-md  text-left text-gray-300">Email - gyanesh@bombaygifts.net</text>
      </div>
      </div>
      </div>
  )
}
export default Footer