import React from 'react'
import tw from 'twin.macro'
import {AboutTabTwo, CategoryList, HeaderTitle} from "../organisms";

const Span = tw.span`font-semibold text-gray-900`
const H6 = tw.h6`text-justify mt-2 mb-4  text-gray-900`

function AboutUsTemplate() {
  return (
   <div className=" bg-gray-100">
      <HeaderTitle title="About us"/>
    <div className="md:flex max-w-screen-xl md:py-10 md:p-5 mx-auto">
      <div className="md:w-6/12 py-5 md:pr-5 p-3">
      <h5 className="text-2xl md:text-3xl font-semibold text-gray-900 text-left">Who We Are</h5>
     <H6>Bombay Gifts , we are one of the top and best Corporate gifting companies in India. We have
            <Span>“15 Years of Excellence”</Span> in the industry of <Span>“Gifts &amp; Novelties”</Span> and offering staggering choices of high quality promotional products at competitive prices, breakneck turnaround times with complete reliability to our clients.</H6>
          <H6>
            At Bombay Gifts, you’ll find the perfect gifts for the perfect occasions for all your business needs.
          </H6>
          <H6>For our Service and Quality, we have received the Prestigious <Span>“Quality Brands India Award”</Span> and <Span>“National Industrial Excellence Award”</Span> from National Chamber of Commerce & Industry of India.
          </H6>
          <H6>Our professionals are works deliberately to meet the customers’ expectations and their Satisfaction time-to-time to deliver the Best.          </H6>
          <H6>If you are looking for Traditional, Modern, Unique and cost-effective gift ideas that tie in with your business, you have come to the right place. </H6>
      </div>
      <div className="md:w-6/12 py-5 md:pr-5 p-3">
         <h5 className="text-2xl md:text-3xl font-semibold text-gray-900 text-left">Award &amp; Accolades</h5>
        <CategoryList/>
      </div>
      </div>
      <AboutTabTwo/>
    </div>
  )
}

export default AboutUsTemplate