import React from 'react';
import {InputText, Text} from "../atoms";

function InputWithText({text,placeholder,inputValue, name, type}) {
  return <div className="flex-col flex">
    <Text Style="font-semibold text-left" text={text}/>
      <InputText name={name} type={type} placeholder={placeholder} inputStyle=" outline-none w-full px-2 text-bold border text-gray-900 py-2 border-gray-300 font-normal md:py-2  rounded-md" text="" />
      </div>
}

export {InputWithText};
