import React from 'react'
import AboutUsTemplate from "../templates/AboutUsTemplate";

function AboutUs() {
  return (
    <div>
      <AboutUsTemplate/>
    </div>
  )
}

export default AboutUs