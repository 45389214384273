import React from 'react'
import {NavLink} from "react-router-dom";

//images
import logo from '../../assets/images/logo.png'


function Header() {
  return (
    <div className="flex items-center justify-between max-w-screen-xl mx-auto py-6">
      <img className="md:h-16 h-20" src={logo} alt="logo"/>
      <div>
        <NavLink className="text-xl mx-1 font-semibold focus:border-b-4 focus:border-red-500 text-gray-800" to="/">Home</NavLink>
        <NavLink className="text-xl mx-1 font-semibold text-gray-800 focus:border-b-4 focus:border-red-500"  to="/about_us">About Us</NavLink>
        <NavLink className="text-xl mx-1 font-semibold text-gray-800 focus:border-b-4 focus:border-red-500" to="/products/All">Products</NavLink>
        <NavLink className="text-xl mx-1 font-semibold text-gray-800 focus:border-b-4 focus:border-red-500" to="/achievements">Achievements</NavLink>
        <NavLink className="text-xl mx-1 font-semibold text-gray-800 focus:border-b-4 focus:border-red-500" to="/contact">Contact</NavLink>
      </div>
    </div>
  )
}

export default Header