import React from 'react'
import {AchievementsTemplate} from "../templates";

function Achievements() {
  return (
    <div className="">
      <AchievementsTemplate/>
    </div>
  )
}

export default Achievements