import React from 'react'
import {HeaderTitle, ProductFilter} from "../organisms";

function ProductTemplate() {
  return (
    <div>
      <HeaderTitle title="Products" />
      <ProductFilter/>
    </div>
  )
}

export default ProductTemplate