import React from 'react'

import Slider from "react-slick";

function HomeSlider() {

    var settings = {
      dots: true,
      infinite: true,
      arrows:false,
      accessibility:false,
      autoplay: true,
      speed: 1000,
 
  };
  return (
     <Slider className="my-5" {...settings}>
      <div>
        <img className="mx-auto" src={require('../../assets/images/slider1.jpg')} />
      </div>
      <div>
        <img className="mx-auto" src={require('../../assets/images/slider2.jpg')} />
      </div>
      <div>
        <img className="mx-auto" src={require('../../assets/images/slider3.jpg')} />
      </div>
      <div>
       <img className="mx-auto" src={require('../../assets/images/slider4.jpg')} />
      </div>
      <div>
       <img className="mx-auto" src={require('../../assets/images/slider5.jpg')} />
      </div>
      <div>
       <img className="mx-auto" src={require('../../assets/images/slider6.jpg')} />
      </div>
      <div>
       <img className="mx-auto" src={require('../../assets/images/slider7.jpg')} />
      </div>
      <div>
       <img className="mx-auto" src={require('../../assets/images/slider8.jpg')} />
      </div>
    </Slider>
      
  )
}

export default HomeSlider