import React,{useEffect} from 'react'
import {HeaderTitle} from "../organisms";
import {ContactForm} from "../organisms/ContactForm";
import {Text} from '../atoms'

import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

function ContactUsTemplate() {
  useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
        });
 
  }, [])
  
  return (
    <div className="bg-gray-100">
      <HeaderTitle title="Contact us" />
      <div className="lg:flex justify-between max-w-screen-xl mx-auto py-5 lg:py-10">
        <ContactForm divStyle="lg:w-6/12 pr-10" />
        <div className="lg:w-5/12 pl-5 lg:pl-10">
        <div className="">
          <h6 className="text-2xl lg:text-3xl py-3 text-left text-gray-900 font-semibold">Context Info</h6>
             <div className="flex flex-col justify-center  ">
      <Text Style="text-lg lg:text-xl text-gray-800 font-semibold text-left  " text="Head Office:" />
        <Text Style="text-lg lg:text-xl text-gray-800 font-semibold text-left" text="BombayGifits" />
        <text className="text-sm lg:text-md  text-left text-gray-900 mt-2">No 92, Pedariyar Koil Street, 1st Floor, Seven Wells, Chennai – 600 001. Tamil Nadu, India.</text>
        <text className="text-sm lg:text-md  text-left text-gray-900">Mob +91 94443 09945</text>
        <text className="text-sm lg:text-md  text-left text-gray-900">Mob +91 99405 59443</text>
        <text className="text-sm lg:text-md  text-left text-gray-900">Email - bombay.gifts@gmail.com</text>
      </div>
          </div>
           <div className="flex flex-col justify-center py-3">
        <Text Style="text-xl text-gray-800 text-left font-semibold" text="BombayGifits" />
        <text className="text-sm lg:text-md  text-left text-gray-900">No 16, Jai Bhawani Co-Op Housing Society, Ground Floor, Khamba Dev Nagar Sion,
Dharavi – 400 017.
Mumbai, India.</text>
        <text className="text-sm lg:text-md  text-left text-gray-900">Mob +91 99679 36383</text>
            <h6 className="text-sm lg:text-md  text-left text-gray-900">Email - gyanesh@bombaygifts.net</h6>
            
            <div className="mt-4">
              <h6 className="text-sm lg:text-md  text-left text-gray-900">MS.Raj Nandani Thakur</h6>
              <h6 className="text-sm lg:text-md  text-left text-gray-900">Mob +91 9029311001</h6>
              <h6 className="text-sm lg:text-md  text-left text-gray-900">rajnandani.bombaygifts@gmail.com</h6>
              
            </div>
            <div className="mt-4">
              <h6 className="text-sm lg:text-md  text-left text-gray-900">Mr.Gyanesh Kumar</h6>
              <h6 className="text-sm lg:text-md  text-left text-gray-900">Mob +91 9967936383</h6>
              <h6 className="text-sm lg:text-md  text-left text-gray-900">gyanesh.bombaygifts@gmail.com</h6>
              
            </div>

            <h6 className=" text-xl lg:text-2xl py-3 text-left text-gray-900 font-semibold">Social Link</h6>
            <div className="flex "> 

            <div className="w-10 h-10 mr-2 rounded-sm bg-gray-800 p-3">
            <a href="https://www.facebook.com/" target="_blank"> <FaFacebookF className="text-gray-300  cursor-pointer hover:text-header_bg"/></a></div>
         <div className="w-10 ml-2 h-10 rounded-sm bg-gray-800 p-3">
              <a href="https://www.linkedin.com/" target="_blank">  <FaLinkedinIn className="text-gray-300  cursor-pointer hover:text-header_bg" /></a>
              </div>
            </div>

      </div>
      </div>
        </div>
     
    </div>
  )
}

export default ContactUsTemplate