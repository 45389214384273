import React from 'react'
import { GiHighFive } from "react-icons/gi";
import {HandIconWithText} from "../molecules";

function CategoryList() {
  return (
    <div className=" mt-4">
      <HandIconWithText text="Bags" />
      <HandIconWithText text="Caps" />
      <HandIconWithText text="T-shirts" />
      <HandIconWithText text="Handicrafts" />
      <HandIconWithText text="Leather" />
      <HandIconWithText text="Tabletop Displays" />
      <HandIconWithText text="Travel" />
      <HandIconWithText text="Trophies &amp; Medals" />
      <HandIconWithText text="Watches" />
      <HandIconWithText text="Others" />
    </div>
  )
}

export default CategoryList