import React from 'react'
import ImageHoverCard from "../molecules/ImageHoverCard";
import {CategoryList, HeaderTitle} from '../organisms'

function AchievementsTemplate() {

  const list = [
    {
      id: 1,
      image: require('../../assets/images/achievone.jpg'),
      text:"Quality Brand Awards"
    },
    {
      id: 2,
      image: require('../../assets/images/achievtwo.jpg'),
      text:"Quality Brand Award",
    },
    {
      id: 3,
      image: require('../../assets/images/achievthree.jpg'),
      text:"Quality Brand Certificate",
    },
    {
      id: 4,
      image: require('../../assets/images/achievfour.jpg'),
      text:"NCCII Certificate"
    },
  ]
  return (
    <div className=" bg-gray-100">
      <HeaderTitle title="Achievements"/>
    <div className="md:flex max-w-screen-xl md:py-10  md:p-5 mx-auto">
      <div className="md:w-6/12 py-5 md:pr-5 p-3">
      <h5 className="text-2xl md:text-3xl font-semibold text-gray-900 text-left">Award &amp; Accolades</h5>
      <div className="grid grid-cols-2 gap-2 py-3">
        {list.map(item => (
          <ImageHoverCard text={item.text} image={item.image}/>
        ))}
       
      </div>
      </div>
      <div className="py-5 p-3 md:pl-5 md:w-6/12">
         <h5 className="text-2xl md:text-3xl font-semibold text-gray-900 text-left">Award &amp; Accolades</h5>
        <CategoryList/>
      </div>
      </div>

    </div>
  )
}

export default AchievementsTemplate