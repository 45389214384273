export const tableTop = [
  {
    image: require('../assets/images/table_top/eld1.jpg'),
    text: "Tabletop",
    id: "ELD001",
  },
  {
    image: require('../assets/images/table_top/eld2.jpg'),
    text: "Tabletop",
    id: "ELD002",
  },
  {
    image: require('../assets/images/table_top/eld3.jpg'),
    text: "Tabletop",
    id: "ELD003",
  },
  {
    image: require('../assets/images/table_top/eld4.jpg'),
    text: "Tabletop",
    id: "ELD004",
  },
  {
    image: require('../assets/images/table_top/eld5.jpg'),
    text: "Tabletop",
    id: "ELD005",
  },

  {
    image: require('../assets/images/table_top/eld6.jpg'),
    text: "Tabletop",
    id: "ELD006",
  },
  {
    image: require('../assets/images/table_top/eld7.jpg'),
    text: "Tabletop",
    id: "ELD007",
  },
  {
    image: require('../assets/images/table_top/elg1.jpg'),
    text: "Tabletop",
    id: "ELG001",
  },
  {
    image: require('../assets/images/table_top/elg2.jpg'),
    text: "Tabletop",
    id: "ELG002",
  },
  {
    image: require('../assets/images/table_top/wm1.jpg'),
    text: "Tabletop",
    id: "WMD001",
  },
  {
    image: require('../assets/images/table_top/wm2.jpg'),
    text: "Tabletop",
    id: "WMD002",
  },
  {
    image: require('../assets/images/table_top/wm3.jpg'),
    text: "Tabletop",
    id: "WMD003",
  },
  {
    image: require('../assets/images/table_top/wm4.jpg'),
    text: "Tabletop",
    id: "WMD004",
  },
  {
    image: require('../assets/images/table_top/wm5.jpg'),
    text: "Tabletop",
    id: "WMD005",
  },
  {
    image: require('../assets/images/table_top/wm6.jpg'),
    text: "Tabletop",
    id: "WMD006",
  },
  {
    image: require('../assets/images/table_top/wm7.jpg'),
    text: "Tabletop",
    id: "WMD007",
  },
  {
    image: require('../assets/images/table_top/wm8.jpg'),
    text: "Tabletop",
    id: "WMD008",
  },

  {
    image: require('../assets/images/table_top/wm9.jpg'),
    text: "Tabletop",
    id: "WMD009",
  },
  {
    image: require('../assets/images/table_top/wm10.jpg'),
    text: "Tabletop",
    id: "WMD010",
  },
  {
    image: require('../assets/images/table_top/wm11.jpg'),
    text: "Tabletop",
    id: "WMD011",
  },
  {
    image: require('../assets/images/table_top/wm12.jpg'),
    text: "Tabletop",
    id: "WMD012",
  },
  {
    image: require('../assets/images/table_top/wm13.jpg'),
    text: "Tabletop",
    id: "WMD013",
  },
  {
    image: require('../assets/images/table_top/wm14.jpg'),
    text: "Tabletop",
    id: "WMD014",
  },
  {
    image: require('../assets/images/table_top/wm15.jpg'),
    text: "Tabletop",
    id: "WMD015",
  },
  {
    image: require('../assets/images/table_top/wm16.jpg'),
    text: "Tabletop",
    id: "WMD016",
  },
  {
    image: require('../assets/images/table_top/wm17.jpg'),
    text: "Tabletop",
    id: "WMD017",
  },
  {
    image: require('../assets/images/table_top/wm18.jpg'),
    text: "Tabletop",
    id: "WMD018",
  },
  {
    image: require('../assets/images/table_top/wm19.jpg'),
    text: "Tabletop",
    id: "WMD019",
  },
  {
    image: require('../assets/images/table_top/wm20.jpg'),
    text: "Tabletop",
    id: "WMD020",
  },
  {
    image: require('../assets/images/table_top/wm21.jpg'),
    text: "Tabletop",
    id: "WMD021",
  },
  {
    image: require('../assets/images/table_top/wm22.jpg'),
    text: "Tabletop",
    id: "WMD022",
  },
  {
    image: require('../assets/images/table_top/wm23.jpg'),
    text: "Tabletop",
    id: "WMD023",
  },
  {
    image: require('../assets/images/table_top/wm24.jpg'),
    text: "Tabletop",
    id: "WMD024",
  },
  {
    image: require('../assets/images/table_top/wm25.jpg'),
    text: "Tabletop",
    id: "WMD025",
  },
  {
    image: require('../assets/images/table_top/wm26.jpg'),
    text: "Tabletop",
    id: "WMD026",
  },
  {
    image: require('../assets/images/table_top/wm27.jpg'),
    text: "Tabletop",
    id: "WMD027",
  },
  {
    image: require('../assets/images/table_top/wm28.jpg'),
    text: "Tabletop",
    id: "WMD028",
  },
  {
    image: require('../assets/images/table_top/wm29.jpg'),
    text: "Tabletop",
    id: "WMD029",
  },
  {
    image: require('../assets/images/table_top/wm30.jpg'),
    text: "Tabletop",
    id: "WMD030",
  },
  {
    image: require('../assets/images/table_top/wm31.jpg'),
    text: "Tabletop",
    id: "WMD031",
  },
  {
    image: require('../assets/images/table_top/wm32.jpg'),
    text: "Tabletop",
    id: "WMD032",
  },
  {
    image: require('../assets/images/table_top/wm33.jpg'),
    text: "Tabletop",
    id: "WMD033",
  },

 
];

export const travel = [
  {
    image: require('../assets/images/travel/tro1.jpg'),
    text: "Tabletop",
    id: "TRO001",
  },
  {
    image: require('../assets/images/travel/tro2.jpg'),
    text: "Tabletop",
    id: "TRO002",
  },
  {
    image: require('../assets/images/travel/tro3.jpg'),
    text: "Tabletop",
    id: "TRO003",
  },
  {
    image: require('../assets/images/travel/tro4.jpg'),
    text: "Tabletop",
    id: "TRO004",
  },
  {
    image: require('../assets/images/travel/tro5.jpg'),
    text: "Tabletop",
    id: "TRO005",
  },
  {
    image: require('../assets/images/travel/jac1.jpg'),
    text: "Tabletop",
    id: "JAC001",
  },
  {
    image: require('../assets/images/travel/jac2.jpg'),
    text: "Tabletop",
    id: "JAC002",
  },
  {
    image: require('../assets/images/travel/jac3.jpg'),
    text: "Tabletop",
    id: "JAC003",
  },
  {
    image: require('../assets/images/travel/jac4.jpg'),
    text: "Tabletop",
    id: "JAC004",
  },
  {
    image: require('../assets/images/travel/jac5.jpg'),
    text: "Tabletop",
    id: "JAC005",
  },
  {
    image: require('../assets/images/travel/jac6.jpg'),
    text: "Tabletop",
    id: "JAC006",
  },
  {
    image: require('../assets/images/travel/jac7.jpg'),
    text: "Tabletop",
    id: "JAC007",
  },
  {
    image: require('../assets/images/travel/jac8.jpg'),
    text: "Tabletop",
    id: "JAC008",
  },
  {
    image: require('../assets/images/travel/jac9.jpg'),
    text: "Tabletop",
    id: "JAC009",
  },
  {
    image: require('../assets/images/travel/jac10.jpg'),
    text: "Tabletop",
    id: "JAC010",
  },
  {
    image: require('../assets/images/travel/jac11.jpg'),
    text: "Tabletop",
    id: "JAC011",
  },
  {
    image: require('../assets/images/travel/jac12.jpg'),
    text: "Tabletop",
    id: "JAC012",
  },
  {
    image: require('../assets/images/travel/jac13.jpg'),
    text: "Tabletop",
    id: "JAC013",
  },
  {
    image: require('../assets/images/travel/jac14.jpg'),
    text: "Tabletop",
    id: "JAC014",
  },
  {
    image: require('../assets/images/travel/jac15.jpg'),
    text: "Tabletop",
    id: "JAC016",
  },
  {
    image: require('../assets/images/travel/jac17.jpg'),
    text: "Tabletop",
    id: "JAC017",
  },
  {
    image: require('../assets/images/travel/jac18.jpg'),
    text: "Tabletop",
    id: "JAC018",
  },
  {
    image: require('../assets/images/travel/jac19.jpg'),
    text: "Tabletop",
    id: "JAC019",
  },
];

export const trophies = [
  {
    image: require('../assets/images/trophies/act1.jpg'),
    text: "Trophies",
    id: "ACT001",
  },
  {
    image: require('../assets/images/trophies/act2.jpg'),
    text: "Trophies",
    id: "ACT002",
  },
  {
    image: require('../assets/images/trophies/act3.jpg'),
    text: "Trophies",
    id: "ACT003",
  },
  {
    image: require('../assets/images/trophies/act4.jpg'),
    text: "Trophies",
    id: "ACT004",
  },
  {
    image: require('../assets/images/trophies/act5.jpg'),
    text: "Trophies",
    id: "ACT005",
  },
  {
    image: require('../assets/images/trophies/act6.jpg'),
    text: "Trophies",
    id: "ACT006",
  },
  {
    image: require('../assets/images/trophies/act7.jpg'),
    text: "Trophies",
    id: "ACT007",
  },
  {
    image: require('../assets/images/trophies/act8.jpg'),
    text: "Trophies",
    id: "ACT008",
  },
  {
    image: require('../assets/images/trophies/act9.jpg'),
    text: "Trophies",
    id: "ACT009",
  },
  {
    image: require('../assets/images/trophies/act10.jpg'),
    text: "Trophies",
    id: "ACT010",
  },
  {
    image: require('../assets/images/trophies/act11.jpg'),
    text: "Trophies",
    id: "ACT011",
  },
  {
    image: require('../assets/images/trophies/act12.jpg'),
    text: "Trophies",
    id: "ACT012",
  },
  {
    image: require('../assets/images/trophies/act13.jpg'),
    text: "Trophies",
    id: "ACT013",
  },
  {
    image: require('../assets/images/trophies/act14.jpg'),
    text: "Trophies",
    id: "ACT014",
  },
  {
    image: require('../assets/images/trophies/act15.jpg'),
    text: "Trophies",
    id: "ACT015",
  },
  {
    image: require('../assets/images/trophies/act16.jpg'),
    text: "Trophies",
    id: "ACT016",
  },
  {
    image: require('../assets/images/trophies/act17.jpg'),
    text: "Trophies",
    id: "ACT017",
  },
  {
    image: require('../assets/images/trophies/act18.jpg'),
    text: "Trophies",
    id: "ACT018",
  },
  {
    image: require('../assets/images/trophies/act19.jpg'),
    text: "Trophies",
    id: "ACT019",
  },
  {
    image: require('../assets/images/trophies/act20.jpg'),
    text: "Trophies",
    id: "ACT020",
  },
  {
    image: require('../assets/images/trophies/act21.jpg'),
    text: "Trophies",
    id: "ACT021",
  },
  {
    image: require('../assets/images/trophies/act22.jpg'),
    text: "Trophies",
    id: "ACT022",
  },
  {
    image: require('../assets/images/trophies/act23.jpg'),
    text: "Trophies",
    id: "ACT023",
  },
  {
    image: require('../assets/images/trophies/act24.jpg'),
    text: "Trophies",
    id: "ACT024",
  },
  {
    image: require('../assets/images/trophies/act25.jpg'),
    text: "Trophies",
    id: "ACT025",
  },
  {
    image: require('../assets/images/trophies/act26.jpg'),
    text: "Trophies",
    id: "ACT026",
  },
  {
    image: require('../assets/images/trophies/act27.jpg'),
    text: "Trophies",
    id: "ACT027",
  },
  {
    image: require('../assets/images/trophies/act28.jpg'),
    text: "Trophies",
    id: "ACT028",
  },
  {
    image: require('../assets/images/trophies/act29.jpg'),
    text: "Trophies",
    id: "ACT029",
  },
  {
    image: require('../assets/images/trophies/act30.jpg'),
    text: "Trophies",
    id: "ACT030",
  },
  {
    image: require('../assets/images/trophies/act31.jpg'),
    text: "Trophies",
    id: "ACT031",
  },
  {
    image: require('../assets/images/trophies/act32.jpg'),
    text: "Trophies",
    id: "ACT032",
  },
  {
    image: require('../assets/images/trophies/act33.jpg'),
    text: "Trophies",
    id: "ACT033",
  },
  {
    image: require('../assets/images/trophies/act34.jpg'),
    text: "Trophies",
    id: "ACT034",
  },
  {
    image: require('../assets/images/trophies/act35.jpg'),
    text: "Trophies",
    id: "ACT035",
  },
  {
    image: require('../assets/images/trophies/act36.jpg'),
    text: "Trophies",
    id: "ACT036",
  },
  {
    image: require('../assets/images/trophies/act37.jpg'),
    text: "Trophies",
    id: "ACT038",
  },
  {
    image: require('../assets/images/trophies/act38.jpg'),
    text: "Trophies",
    id: "ACT038",
  },
  {
    image: require('../assets/images/trophies/act39.jpg'),
    text: "Trophies",
    id: "ACT039",
  },
  {
    image: require('../assets/images/trophies/act40.jpg'),
    text: "Trophies",
    id: "ACT040",
  },
  {
    image: require('../assets/images/trophies/act41.jpg'),
    text: "Trophies",
    id: "ACT041",
  },
  {
    image: require('../assets/images/trophies/act42.jpg'),
    text: "Trophies",
    id: "ACT042",
  },
  {
    image: require('../assets/images/trophies/act43.jpg'),
    text: "Trophies",
    id: "ACT043",
  },
  {
    image: require('../assets/images/trophies/act44.jpg'),
    text: "Trophies",
    id: "ACT044",
  },
  {
    image: require('../assets/images/trophies/act45.jpg'),
    text: "Trophies",
    id: "ACT045",
  },
  {
    image: require('../assets/images/trophies/act46.jpg'),
    text: "Trophies",
    id: "ACT046",
  },
  {
    image: require('../assets/images/trophies/act47.jpg'),
    text: "Trophies",
    id: "ACT047",
  },
  {
    image: require('../assets/images/trophies/act48.jpg'),
    text: "Trophies",
    id: "ACT048",
  },
  {
    image: require('../assets/images/trophies/act49.jpg'),
    text: "Trophies",
    id: "ACT049",
  },
  {
    image: require('../assets/images/trophies/act50.jpg'),
    text: "Trophies",
    id: "ACT050",
  },

  {
    image: require('../assets/images/trophies/act52.jpg'),
    text: "Trophies",
    id: "ACT052",
  },
  {
    image: require('../assets/images/trophies/act53.jpg'),
    text: "Trophies",
    id: "ACT053",
  },
  {
    image: require('../assets/images/trophies/act54.jpg'),
    text: "Trophies",
    id: "ACT054",
  },
  {
    image: require('../assets/images/trophies/act55.jpg'),
    text: "Trophies",
    id: "ACT055",
  },
  {
    image: require('../assets/images/trophies/act56.jpg'),
    text: "Trophies",
    id: "ACT056",
  },
  {
    image: require('../assets/images/trophies/act71.jpg'),
    text: "Trophies",
    id: "ACT071",
  },

  {
    image: require('../assets/images/trophies/act72.jpg'),
    text: "Trophies",
    id: "ACT072",
  },
  {
    image: require('../assets/images/trophies/act73.jpg'),
    text: "Trophies",
    id: "ACT073",
  },
  {
    image: require('../assets/images/trophies/act74.jpg'),
    text: "Trophies",
    id: "ACT074",
  },
  {
    image: require('../assets/images/trophies/act75.jpg'),
    text: "Trophies",
    id: "ACT075",
  },
  {
    image: require('../assets/images/trophies/act76.jpg'),
    text: "Trophies",
    id: "ACT076",
  },
  {
    image: require('../assets/images/trophies/act77.jpg'),
    text: "Trophies",
    id: "ACT077",
  },
  {
    image: require('../assets/images/trophies/act78.jpg'),
    text: "Trophies",
    id: "ACT078",
  },
  {
    image: require('../assets/images/trophies/act79.jpg'),
    text: "Trophies",
    id: "ACT079",
  },
  {
    image: require('../assets/images/trophies/act80.jpg'),
    text: "Trophies",
    id: "ACT080",
  },
  {
    image: require('../assets/images/trophies/act81.jpg'),
    text: "Trophies",
    id: "ACT082",
  },
  {
    image: require('../assets/images/trophies/act82.jpg'),
    text: "Trophies",
    id: "ACT082",
  },
  {
    image: require('../assets/images/trophies/act83.jpg'),
    text: "Trophies",
    id: "ACT083",
  },
  {
    image: require('../assets/images/trophies/act84.jpg'),
    text: "Trophies",
    id: "ACT084",
  },
  {
    image: require('../assets/images/trophies/act85.jpg'),
    text: "Trophies",
    id: "ACT085",
  },
  {
    image: require('../assets/images/trophies/act86.jpg'),
    text: "Trophies",
    id: "ACT086",
  },

  {
    image: require('../assets/images/trophies/crt1.jpg'),
    text: "Trophies",
    id: "CRT001",
  },
  {
    image: require('../assets/images/trophies/crt2.jpg'),
    text: "Trophies",
    id: "CRT002",
  },
  {
    image: require('../assets/images/trophies/crt3.jpg'),
    text: "Trophies",
    id: "CRT003",
  },
  {
    image: require('../assets/images/trophies/crt4.jpg'),
    text: "Trophies",
    id: "CRT004",
  },
  {
    image: require('../assets/images/trophies/crt5.jpg'),
    text: "Trophies",
    id: "CRT005",
  },
  {
    image: require('../assets/images/trophies/crt6.jpg'),
    text: "Trophies",
    id: "CRT006",
  },
  {
    image: require('../assets/images/trophies/crt7.jpg'),
    text: "Trophies",
    id: "CRT007",
  },
  {
    image: require('../assets/images/trophies/crt8.jpg'),
    text: "Trophies",
    id: "CRT008",
  },
  {
    image: require('../assets/images/trophies/crt9.jpg'),
    text: "Trophies",
    id: "CRT009",
  },
  {
    image: require('../assets/images/trophies/crt10.jpg'),
    text: "Trophies",
    id: "CRT010",
  },
  {
    image: require('../assets/images/trophies/crt11.jpg'),
    text: "Trophies",
    id: "CRT011",
  },
  {
    image: require('../assets/images/trophies/crt12.jpg'),
    text: "Trophies",
    id: "CRT012",
  },
  {
    image: require('../assets/images/trophies/crt13.jpg'),
    text: "Trophies",
    id: "CRT013",
  },
  {
    image: require('../assets/images/trophies/crt14.jpg'),
    text: "Trophies",
    id: "CRT014",
  },
  {
    image: require('../assets/images/trophies/crt15.jpg'),
    text: "Trophies",
    id: "CRT015",
  },
  {
    image: require('../assets/images/trophies/crt16.jpg'),
    text: "Trophies",
    id: "CRT016",
  },
  {
    image: require('../assets/images/trophies/crt17.jpg'),
    text: "Trophies",
    id: "CRT017",
  },
  {
    image: require('../assets/images/trophies/crt18.jpg'),
    text: "Trophies",
    id: "CRT018",
  },
  {
    image: require('../assets/images/trophies/crt19.jpg'),
    text: "Trophies",
    id: "CRT019",
  },
  {
    image: require('../assets/images/trophies/crt20.jpg'),
    text: "Trophies",
    id: "CRT020",
  },
  {
    image: require('../assets/images/trophies/crt21.jpg'),
    text: "Trophies",
    id: "CRT021",
  },
  {
    image: require('../assets/images/trophies/crt22.jpg'),
    text: "Trophies",
    id: "CRT022",
  },
  {
    image: require('../assets/images/trophies/crt23.jpg'),
    text: "Trophies",
    id: "CRT023",
  },
  {
    image: require('../assets/images/trophies/crt24.jpg'),
    text: "Trophies",
    id: "CRT024",
  },
  {
    image: require('../assets/images/trophies/crt25.jpg'),
    text: "Trophies",
    id: "CRT025",
  },
  {
    image: require('../assets/images/trophies/crt26.jpg'),
    text: "Trophies",
    id: "CRT026",
  },
  {
    image: require('../assets/images/trophies/crt27.jpg'),
    text: "Trophies",
    id: "CRT027",
  },
  {
    image: require('../assets/images/trophies/ist1.jpg'),
    text: "Trophies",
    id: "IST001",
  },
  {
    image: require('../assets/images/trophies/ist2.jpg'),
    text: "Trophies",
    id: "IST002",
  },
  {
    image: require('../assets/images/trophies/ist3.jpg'),
    text: "Trophies",
    id: "IST003",
  },
  {
    image: require('../assets/images/trophies/ist4.jpg'),
    text: "Trophies",
    id: "IST004",
  },
  {
    image: require('../assets/images/trophies/ist5.jpg'),
    text: "Trophies",
    id: "IST005",
  },
  {
    image: require('../assets/images/trophies/ist6.jpg'),
    text: "Trophies",
    id: "IST006",
  },
  {
    image: require('../assets/images/trophies/ist7.jpg'),
    text: "Trophies",
    id: "IST007",
  },
  {
    image: require('../assets/images/trophies/ist8.jpg'),
    text: "Trophies",
    id: "IST008",
  },
  {
    image: require('../assets/images/trophies/ist9.jpg'),
    text: "Trophies",
    id: "IST009",
  },
  {
    image: require('../assets/images/trophies/ist10.jpg'),
    text: "Trophies",
    id: "IST010",
  },
  {
    image: require('../assets/images/trophies/ist11.jpg'),
    text: "Trophies",
    id: "IST011",
  },
  {
    image: require('../assets/images/trophies/ist12.jpg'),
    text: "Trophies",
    id: "IST012",
  },
  {
    image: require('../assets/images/trophies/ist13.jpg'),
    text: "Trophies",
    id: "IST013",
  },
  {
    image: require('../assets/images/trophies/ist14.jpg'),
    text: "Trophies",
    id: "IST014",
  },
  {
    image: require('../assets/images/trophies/ist15.jpg'),
    text: "Trophies",
    id: "IST015",
  },
  {
    image: require('../assets/images/trophies/ist16.jpg'),
    text: "Trophies",
    id: "IST016",
  },
  {
    image: require('../assets/images/trophies/ist17.jpg'),
    text: "Trophies",
    id: "IST017",
  },
  {
    image: require('../assets/images/trophies/ist18.jpg'),
    text: "Trophies",
    id: "IST018",
  },
  {
    image: require('../assets/images/trophies/ist19.jpg'),
    text: "Trophies",
    id: "IST019",
  },
  {
    image: require('../assets/images/trophies/ist20.jpg'),
    text: "Trophies",
    id: "IST020",
  },
  {
    image: require('../assets/images/trophies/ist21.jpg'),
    text: "Trophies",
    id: "IST021",
  },
  {
    image: require('../assets/images/trophies/ist22.jpg'),
    text: "Trophies",
    id: "IST022",
  },
  {
    image: require('../assets/images/trophies/ist23.jpg'),
    text: "Trophies",
    id: "IST023",
  },
  {
    image: require('../assets/images/trophies/ist24.jpg'),
    text: "Trophies",
    id: "IST024",
  },
  {
    image: require('../assets/images/trophies/ist25.jpg'),
    text: "Trophies",
    id: "IST025",
  },
  {
    image: require('../assets/images/trophies/ist26.jpg'),
    text: "Trophies",
    id: "IST026",
  },
  {
    image: require('../assets/images/trophies/ist27.jpg'),
    text: "Trophies",
    id: "IST027",
  },
  {
    image: require('../assets/images/trophies/ist28.jpg'),
    text: "Trophies",
    id: "IST028",
  },
  {
    image: require('../assets/images/trophies/ist29.jpg'),
    text: "Trophies",
    id: "IST029",
  },
  {
    image: require('../assets/images/trophies/ist30.jpg'),
    text: "Trophies",
    id: "IST030",
  },
  {
    image: require('../assets/images/trophies/ist31.jpg'),
    text: "Trophies",
    id: "IST031",
  },
  {
    image: require('../assets/images/trophies/ist32.jpg'),
    text: "Trophies",
    id: "IST032",
  },
  {
    image: require('../assets/images/trophies/ist33.jpg'),
    text: "Trophies",
    id: "IST033",
  },
  {
    image: require('../assets/images/trophies/ist34.jpg'),
    text: "Trophies",
    id: "IST034",
  },
  {
    image: require('../assets/images/trophies/ist35.jpg'),
    text: "Trophies",
    id: "IST035",
  },
  {
    image: require('../assets/images/trophies/ist36.jpg'),
    text: "Trophies",
    id: "IST036",
  },
  {
    image: require('../assets/images/trophies/ist37.jpg'),
    text: "Trophies",
    id: "IST037",
  },
  {
    image: require('../assets/images/trophies/ist38.jpg'),
    text: "Trophies",
    id: "IST038",
  },
  {
    image: require('../assets/images/trophies/ist39.jpg'),
    text: "Trophies",
    id: "IST039",
  },
  {
    image: require('../assets/images/trophies/ist40.jpg'),
    text: "Trophies",
    id: "IST040",
  },
  {
    image: require('../assets/images/trophies/ist41.jpg'),
    text: "Trophies",
    id: "IST041",
  },
  {
    image: require('../assets/images/trophies/ist42.jpg'),
    text: "Trophies",
    id: "IST042",
  },
  {
    image: require('../assets/images/trophies/ist43.jpg'),
    text: "Trophies",
    id: "IST043",
  },
  {
    image: require('../assets/images/trophies/ist44.jpg'),
    text: "Trophies",
    id: "IST044",
  },
  {
    image: require('../assets/images/trophies/ist45.jpg'),
    text: "Trophies",
    id: "IST045",
  },
  {
    image: require('../assets/images/trophies/ist46.jpg'),
    text: "Trophies",
    id: "IST046",
  },
  {
    image: require('../assets/images/trophies/ist47.jpg'),
    text: "Trophies",
    id: "IST047",
  },
  {
    image: require('../assets/images/trophies/ist48.jpg'),
    text: "Trophies",
    id: "IST048",
  },
  {
    image: require('../assets/images/trophies/ist49.jpg'),
    text: "Trophies",
    id: "IST049",
  },
  {
    image: require('../assets/images/trophies/ist50.jpg'),
    text: "Trophies",
    id: "IST050",
  },
  {
    image: require('../assets/images/trophies/ist51.jpg'),
    text: "Trophies",
    id: "IST051",
  },
  {
    image: require('../assets/images/trophies/ist52.jpg'),
    text: "Trophies",
    id: "IST052",
  },
  {
    image: require('../assets/images/trophies/ist53.jpg'),
    text: "Trophies",
    id: "IST053",
  },
  {
    image: require('../assets/images/trophies/ist54.jpg'),
    text: "Trophies",
    id: "IST054",
  },
  {
    image: require('../assets/images/trophies/ist55.jpg'),
    text: "Trophies",
    id: "IST055",
  },
  {
    image: require('../assets/images/trophies/ist56.jpg'),
    text: "Trophies",
    id: "IST056",
  },
  {
    image: require('../assets/images/trophies/ist57.jpg'),
    text: "Trophies",
    id: "IST057",
  },
  {
    image: require('../assets/images/trophies/ist58.jpg'),
    text: "Trophies",
    id: "IST058",
  },
  {
    image: require('../assets/images/trophies/ist62.jpg'),
    text: "Trophies",
    id: "IST062",
  },
  {
    image: require('../assets/images/trophies/ist63.jpg'),
    text: "Trophies",
    id: "IST063",
  },
  {
    image: require('../assets/images/trophies/ist64.jpg'),
    text: "Trophies",
    id: "IST064",
  },
  {
    image: require('../assets/images/trophies/ist65.jpg'),
    text: "Trophies",
    id: "IST065",
  },
  {
    image: require('../assets/images/trophies/ist67.jpg'),
    text: "Trophies",
    id: "IST067",
  },
  {
    image: require('../assets/images/trophies/ist68.jpg'),
    text: "Trophies",
    id: "IST068",
  },
  {
    image: require('../assets/images/trophies/ist70.jpg'),
    text: "Trophies",
    id: "IST070",
  },
  {
    image: require('../assets/images/trophies/ist71.jpg'),
    text: "Trophies",
    id: "IST071",
  },
  {
    image: require('../assets/images/trophies/ist72.jpg'),
    text: "Trophies",
    id: "IST072",
  },
  {
    image: require('../assets/images/trophies/ist73.jpg'),
    text: "Trophies",
    id: "IST073",
  },
  {
    image: require('../assets/images/trophies/ist74.jpg'),
    text: "Trophies",
    id: "IST074",
  },
  {
    image: require('../assets/images/trophies/ist75.jpg'),
    text: "Trophies",
    id: "IST075",
  },
  {
    image: require('../assets/images/trophies/ist76.jpg'),
    text: "Trophies",
    id: "IST076",
  },
  {
    image: require('../assets/images/trophies/ist77.jpg'),
    text: "Trophies",
    id: "IST077",
  },
  {
    image: require('../assets/images/trophies/ist78.jpg'),
    text: "Trophies",
    id: "IST078",
  },
  {
    image: require('../assets/images/trophies/ist79.jpg'),
    text: "Trophies",
    id: "IST079",
  },

  {
    image: require('../assets/images/trophies/wot80.jpg'),
    text: "Trophies",
    id: "WOT080",
  },
  {
    image: require('../assets/images/trophies/wot1.jpg'),
    text: "Trophies",
    id: "WOT001",
  },
  {
    image: require('../assets/images/trophies/wot2.jpg'),
    text: "Trophies",
    id: "WOT002",
  },
  {
    image: require('../assets/images/trophies/wot3.jpg'),
    text: "Trophies",
    id: "WOT003",
  },
  {
    image: require('../assets/images/trophies/wot4.jpg'),
    text: "Trophies",
    id: "WOT004",
  },
  {
    image: require('../assets/images/trophies/wot5.jpg'),
    text: "Trophies",
    id: "WOT005",
  },
  {
    image: require('../assets/images/trophies/wot6.jpg'),
    text: "Trophies",
    id: "WOT006",
  },
  {
    image: require('../assets/images/trophies/wot7.jpg'),
    text: "Trophies7",
    id: "WOT00",
  },
  {
    image: require('../assets/images/trophies/wot8.jpg'),
    text: "Trophies",
    id: "WOT008",
  },
  {
    image: require('../assets/images/trophies/wot9.jpg'),
    text: "Trophies",
    id: "WOT009",
  },
  {
    image: require('../assets/images/trophies/wot10.jpg'),
    text: "Trophies",
    id: "WOT010",
  },
  {
    image: require('../assets/images/trophies/wot11.jpg'),
    text: "Trophies",
    id: "WOT011",
  },
  {
    image: require('../assets/images/trophies/wot12.jpg'),
    text: "Trophies",
    id: "WOT012",
  },
  {
    image: require('../assets/images/trophies/wot13.jpg'),
    text: "Trophies",
    id: "WOT013",
  },
  {
    image: require('../assets/images/trophies/wot14.jpg'),
    text: "Trophies",
    id: "WOT014",
  },
  {
    image: require('../assets/images/trophies/wot15.jpg'),
    text: "Trophies",
    id: "WOT015",
  },
  {
    image: require('../assets/images/trophies/wot16.jpg'),
    text: "Trophies",
    id: "WOT016",
  },
  {
    image: require('../assets/images/trophies/wot17.jpg'),
    text: "Trophies",
    id: "WOT017",
  },
  {
    image: require('../assets/images/trophies/wot18.jpg'),
    text: "Trophies",
    id: "WOT018",
  },
  {
    image: require('../assets/images/trophies/wot19.jpg'),
    text: "Trophies",
    id: "WOT019",
  },
  {
    image: require('../assets/images/trophies/wot20.jpg'),
    text: "Trophies",
    id: "WOT020",
  },
  {
    image: require('../assets/images/trophies/wot21.jpg'),
    text: "Trophies",
    id: "WOT021",
  },
  {
    image: require('../assets/images/trophies/wot22.jpg'),
    text: "Trophies",
    id: "WOT022",
  },
  {
    image: require('../assets/images/trophies/wot23.jpg'),
    text: "Trophies",
    id: "WOT023",
  },
  {
    image: require('../assets/images/trophies/wot24.jpg'),
    text: "Trophies",
    id: "WOT024",
  },
  {
    image: require('../assets/images/trophies/wot25.jpg'),
    text: "Trophies",
    id: "WOT025",
  },
  {
    image: require('../assets/images/trophies/wot26.jpg'),
    text: "Trophies",
    id: "WOT026",
  },
  {
    image: require('../assets/images/trophies/wot27.jpg'),
    text: "Trophies",
    id: "WOT027",
  },
  {
    image: require('../assets/images/trophies/wot28.jpg'),
    text: "Trophies",
    id: "WOT028",
  },
  {
    image: require('../assets/images/trophies/wot29.jpg'),
    text: "Trophies",
    id: "WOT029",
  },
  {
    image: require('../assets/images/trophies/wot30.jpg'),
    text: "Trophies",
    id: "WOT030",
  },
  {
    image: require('../assets/images/trophies/wot31.jpg'),
    text: "Trophies",
    id: "WOT031",
  },
  {
    image: require('../assets/images/trophies/wot32.jpg'),
    text: "Trophies",
    id: "WOT032",
  },
  {
    image: require('../assets/images/trophies/wot33.jpg'),
    text: "Trophies",
    id: "WOT033",
  },
  {
    image: require('../assets/images/trophies/wot34.jpg'),
    text: "Trophies",
    id: "WOT034",
  },
  {
    image: require('../assets/images/trophies/wot35.jpg'),
    text: "Trophies",
    id: "WOT035",
  },
  {
    image: require('../assets/images/trophies/wot36.jpg'),
    text: "Trophies",
    id: "WOT036",
  },
  {
    image: require('../assets/images/trophies/wot37.jpg'),
    text: "Trophies",
    id: "WOT037",
  },
  {
    image: require('../assets/images/trophies/wot38.jpg'),
    text: "Trophies",
    id: "WOT038",
  },
  {
    image: require('../assets/images/trophies/wot39.jpg'),
    text: "Trophies",
    id: "WOT039",
  },
  {
    image: require('../assets/images/trophies/wot40.jpg'),
    text: "Trophies",
    id: "WOT040",
  },
  {
    image: require('../assets/images/trophies/wot41.jpg'),
    text: "Trophies",
    id: "WOT041",
  },
  {
    image: require('../assets/images/trophies/wot42.jpg'),
    text: "Trophies",
    id: "WOT042",
  },
  {
    image: require('../assets/images/trophies/wot43.jpg'),
    text: "Trophies",
    id: "WOT043",
  },
  {
    image: require('../assets/images/trophies/wot44.jpg'),
    text: "Trophies",
    id: "WOT044",
  },
  {
    image: require('../assets/images/trophies/wot45.jpg'),
    text: "Trophies",
    id: "WOT045",
  },
  {
    image: require('../assets/images/trophies/wot46.jpg'),
    text: "Trophies",
    id: "WOT046",
  },
  {
    image: require('../assets/images/trophies/wot47.jpg'),
    text: "Trophies",
    id: "WOT047",
  },
  {
    image: require('../assets/images/trophies/wot48.jpg'),
    text: "Trophies",
    id: "WOT048",
  },
  {
    image: require('../assets/images/trophies/wot49.jpg'),
    text: "Trophies",
    id: "WOT049",
  },
  {
    image: require('../assets/images/trophies/wot50.jpg'),
    text: "Trophies",
    id: "WOT050",
  },
  {
    image: require('../assets/images/trophies/wot51.jpg'),
    text: "Trophies",
    id: "WOT051",
  },
  {
    image: require('../assets/images/trophies/wot52.jpg'),
    text: "Trophies",
    id: "WOT052",
  },
  {
    image: require('../assets/images/trophies/wot53.jpg'),
    text: "Trophies",
    id: "WOT053",
  },
  {
    image: require('../assets/images/trophies/wot54.jpg'),
    text: "Trophies",
    id: "WOT054",
  },
  {
    image: require('../assets/images/trophies/wot55.jpg'),
    text: "Trophies",
    id: "WOT055",
  },
  {
    image: require('../assets/images/trophies/wot56.jpg'),
    text: "Trophies",
    id: "WOT056",
  },
  {
    image: require('../assets/images/trophies/wot57.jpg'),
    text: "Trophies",
    id: "WOT057",
  },
  {
    image: require('../assets/images/trophies/wot58.jpg'),
    text: "Trophies",
    id: "WOT058",
  },
  {
    image: require('../assets/images/trophies/wot59.jpg'),
    text: "Trophies",
    id: "WOT059",
  },
  {
    image: require('../assets/images/trophies/wot60.jpg'),
    text: "Trophies",
    id: "WOT060",
  },
  {
    image: require('../assets/images/trophies/wot61.jpg'),
    text: "Trophies",
    id: "WOT061",
  },
  {
    image: require('../assets/images/trophies/wot62.jpg'),
    text: "Trophies",
    id: "WOT062",
  },
  {
    image: require('../assets/images/trophies/wot63.jpg'),
    text: "Trophies",
    id: "WOT063",
  },
  {
    image: require('../assets/images/trophies/wot64.jpg'),
    text: "Trophies",
    id: "WOT064",
  },
  {
    image: require('../assets/images/trophies/wot65.jpg'),
    text: "Trophies",
    id: "WOT065",
  },
  {
    image: require('../assets/images/trophies/wot66.jpg'),
    text: "Trophies",
    id: "WOT066",
  },
  {
    image: require('../assets/images/trophies/wot67.jpg'),
    text: "Trophies",
    id: "WOT067",
  },
  {
    image: require('../assets/images/trophies/wot68.jpg'),
    text: "Trophies",
    id: "WOT068",
  },
  {
    image: require('../assets/images/trophies/wot69.jpg'),
    text: "Trophies",
    id: "WOT069",
  },
  {
    image: require('../assets/images/trophies/wot70.jpg'),
    text: "Trophies",
    id: "WOT070",
  },
  {
    image: require('../assets/images/trophies/wot71.jpg'),
    text: "Trophies",
    id: "WOT071",
  },
  {
    image: require('../assets/images/trophies/wot72.jpg'),
    text: "Trophies",
    id: "WOT072",
  },
  {
    image: require('../assets/images/trophies/wot73.jpg'),
    text: "Trophies",
    id: "WOT073",
  },
  {
    image: require('../assets/images/trophies/wot74.jpg'),
    text: "Trophies",
    id: "WOT074",
  },
  {
    image: require('../assets/images/trophies/wot75.jpg'),
    text: "Trophies",
    id: "WOT075",
  },
  {
    image: require('../assets/images/trophies/wot76.jpg'),
    text: "Trophies",
    id: "WOT076",
  },
  {
    image: require('../assets/images/trophies/wot77.jpg'),
    text: "Trophies",
    id: "WOT077",
  },
  {
    image: require('../assets/images/trophies/wot78.jpg'),
    text: "Trophies",
    id: "WOT078",
  },

  {
    image: require('../assets/images/trophies/wot80.jpg'),
    text: "Trophies",
    id: "WOT080",
  },
  {
    image: require('../assets/images/trophies/wot81.jpg'),
    text: "Trophies",
    id: "WOT081",
  },
  {
    image: require('../assets/images/trophies/wot82.jpg'),
    text: "Trophies",
    id: "WOT082",
  },
  {
    image: require('../assets/images/trophies/wot83.jpg'),
    text: "Trophies",
    id: "WOT083",
  },
  {
    image: require('../assets/images/trophies/wot84.jpg'),
    text: "Trophies",
    id: "WOT084",
  },
  {
    image: require('../assets/images/trophies/wot85.jpg'),
    text: "Trophies",
    id: "WOT085",
  },
  {
    image: require('../assets/images/trophies/wot86.jpg'),
    text: "Trophies",
    id: "WOT086",
  },
  {
    image: require('../assets/images/trophies/wot87.jpg'),
    text: "Trophies",
    id: "WOT087",
  },
  {
    image: require('../assets/images/trophies/wot88.jpg'),
    text: "Trophies",
    id: "WOT088",
  },
  {
    image: require('../assets/images/trophies/wot89.jpg'),
    text: "Trophies",
    id: "WOT089",
  },
  {
    image: require('../assets/images/trophies/wot90.jpg'),
    text: "Trophies",
    id: "WOT090",
  },
  {
    image: require('../assets/images/trophies/wot91.jpg'),
    text: "Trophies",
    id: "WOT091",
  },
  {
    image: require('../assets/images/trophies/wot92.jpg'),
    text: "Trophies",
    id: "WOT092",
  },
  {
    image: require('../assets/images/trophies/wot93.jpg'),
    text: "Trophies",
    id: "WOT093",
  },
  {
    image: require('../assets/images/trophies/brt1.jpg'),
    text: "Trophies",
    id: "BRT001",
  },
  {
    image: require('../assets/images/trophies/brt2.jpg'),
    text: "Trophies",
    id: "BRT002",
  },
  {
    image: require('../assets/images/trophies/brt3.jpg'),
    text: "Trophies",
    id: "BRT003",
  },
  {
    image: require('../assets/images/trophies/brt4.jpg'),
    text: "Trophies",
    id: "BRT004",
  },
  {
    image: require('../assets/images/trophies/brt5.jpg'),
    text: "Trophies",
    id: "BRT005",
  },
  {
    image: require('../assets/images/trophies/brt6.jpg'),
    text: "Trophies",
    id: "BRT006",
  },
  {
    image: require('../assets/images/trophies/brt7.jpg'),
    text: "Trophies",
    id: "BRT007",
  },
  {
    image: require('../assets/images/trophies/brt8.jpg'),
    text: "Trophies",
    id: "BRT008",
  },
  {
    image: require('../assets/images/trophies/brt9.jpg'),
    text: "Trophies",
    id: "BRT009",
  },
  {
    image: require('../assets/images/trophies/brt10.jpg'),
    text: "Trophies",
    id: "BRT010",
  },
  {
    image: require('../assets/images/trophies/brt11.jpg'),
    text: "Trophies",
    id: "BRT011",
  },
  {
    image: require('../assets/images/trophies/brt12.jpg'),
    text: "Trophies",
    id: "BRT012",
  },
  {
    image: require('../assets/images/trophies/brt13.jpg'),
    text: "Trophies",
    id: "BRT013",
  },
  {
    image: require('../assets/images/trophies/brt14.jpg'),
    text: "Trophies",
    id: "BRT014",
  },
  {
    image: require('../assets/images/trophies/brt15.jpg'),
    text: "Trophies",
    id: "BRT015",
  },
  {
    image: require('../assets/images/trophies/brt16.jpg'),
    text: "Trophies",
    id: "BRT016",
  },
  {
    image: require('../assets/images/trophies/brt17.jpg'),
    text: "Trophies",
    id: "BRT017",
  },
  {
    image: require('../assets/images/trophies/brt18.jpg'),
    text: "Trophies",
    id: "BRT018",
  },
  {
    image: require('../assets/images/trophies/brt19.jpg'),
    text: "Trophies",
    id: "BRT019",
  },
  {
    image: require('../assets/images/trophies/brt20.jpg'),
    text: "Trophies",
    id: "BRT020",
  },
  {
    image: require('../assets/images/trophies/brt21.jpg'),
    text: "Trophies",
    id: "BRT021",
  },
  {
    image: require('../assets/images/trophies/brt22.jpg'),
    text: "Trophies",
    id: "BRT022",
  },
  {
    image: require('../assets/images/trophies/brt23.jpg'),
    text: "Trophies",
    id: "BRT023",
  },
  {
    image: require('../assets/images/trophies/brt24.jpg'),
    text: "Trophies",
    id: "BRT024",
  },
  {
    image: require('../assets/images/trophies/brt25.jpg'),
    text: "Trophies",
    id: "BRT025",
  },
  {
    image: require('../assets/images/trophies/brt26.jpg'),
    text: "Trophies",
    id: "BRT026",
  },
  {
    image: require('../assets/images/trophies/brt27.jpg'),
    text: "Trophies",
    id: "BRT027",
  },
  {
    image: require('../assets/images/trophies/brt28.jpg'),
    text: "Trophies",
    id: "BRT028",
  },
  {
    image: require('../assets/images/trophies/brt29.jpg'),
    text: "Trophies",
    id: "BRT029",
  },
  {
    image: require('../assets/images/trophies/brt30.jpg'),
    text: "Trophies",
    id: "BRT030",
  },
  {
    image: require('../assets/images/trophies/brt31.jpg'),
    text: "Trophies",
    id: "BRT031",
  },
  {
    image: require('../assets/images/trophies/brt32.jpg'),
    text: "Trophies",
    id: "BRT032",
  },
  {
    image: require('../assets/images/trophies/brt33.jpg'),
    text: "Trophies",
    id: "BRT033",
  },
  {
    image: require('../assets/images/trophies/brt34.jpg'),
    text: "Trophies",
    id: "BRT034",
  },
  {
    image: require('../assets/images/trophies/brt35.jpg'),
    text: "Trophies",
    id: "BRT035",
  },
  {
    image: require('../assets/images/trophies/brt36.jpg'),
    text: "Trophies",
    id: "BRT036",
  },
  {
    image: require('../assets/images/trophies/brt37.jpg'),
    text: "Trophies",
    id: "BRT037",
  },
  {
    image: require('../assets/images/trophies/brt38.jpg'),
    text: "Trophies",
    id: "BRT038",
  },

  {
    image: require('../assets/images/trophies/brt69.jpg'),
    text: "Trophies",
    id: "BRT069",
  },
  {
    image: require('../assets/images/trophies/brt70.jpg'),
    text: "Trophies",
    id: "BRT070",
  },
  {
    image: require('../assets/images/trophies/brt71.jpg'),
    text: "Trophies",
    id: "BRT071",
  },
  {
    image: require('../assets/images/trophies/brt72.jpg'),
    text: "Trophies",
    id: "BRT072",
  },
  {
    image: require('../assets/images/trophies/brt73.jpg'),
    text: "Trophies",
    id: "BRT073",
  },
  {
    image: require('../assets/images/trophies/brt74.jpg'),
    text: "Trophies",
    id: "BRT074",
  },
  {
    image: require('../assets/images/trophies/brt75.jpg'),
    text: "Trophies",
    id: "BRT075",
  },
  {
    image: require('../assets/images/trophies/brt76.jpg'),
    text: "Trophies",
    id: "BRT076",
  },
  {
    image: require('../assets/images/trophies/brt77.jpg'),
    text: "Trophies",
    id: "BRT077",
  },
  {
    image: require('../assets/images/trophies/brt78.jpg'),
    text: "Trophies",
    id: "BRT078",
  },
  {
    image: require('../assets/images/trophies/brt79.jpg'),
    text: "Trophies",
    id: "BRT079",
  },
  {
    image: require('../assets/images/trophies/brt80.jpg'),
    text: "Trophies",
    id: "BRT080",
  },
  {
    image: require('../assets/images/trophies/brt81.jpg'),
    text: "Trophies",
    id: "BRT081",
  },
  {
    image: require('../assets/images/trophies/brt82.jpg'),
    text: "Trophies",
    id: "BRT082",
  },
  {
    image: require('../assets/images/trophies/brt83.jpg'),
    text: "Trophies",
    id: "BRT083",
  },
  {
    image: require('../assets/images/trophies/brt84.jpg'),
    text: "Trophies",
    id: "BRT084",
  },
  {
    image: require('../assets/images/trophies/brt85.jpg'),
    text: "Trophies",
    id: "BRT085",
  },
  {
    image: require('../assets/images/trophies/brt86.jpg'),
    text: "Trophies",
    id: "BRT086",
  },
  {
    image: require('../assets/images/trophies/brt87.jpg'),
    text: "Trophies",
    id: "BRT087",
  },
  {
    image: require('../assets/images/trophies/brt88.jpg'),
    text: "Trophies",
    id: "BRT088",
  },
  {
    image: require('../assets/images/trophies/brt89.jpg'),
    text: "Trophies",
    id: "BRT089",
  },
  {
    image: require('../assets/images/trophies/brt90.jpg'),
    text: "Trophies",
    id: "BRT090",
  },
  {
    image: require('../assets/images/trophies/brt91.jpg'),
    text: "Trophies",
    id: "BRT091",
  },
  {
    image: require('../assets/images/trophies/brt92.jpg'),
    text: "Trophies",
    id: "BRT092",
  },
,
  {
    image: require('../assets/images/trophies/brt69.jpg'),
    text: "Trophies",
    id: "BRT069",
  },
  {
    image: require('../assets/images/trophies/brt70.jpg'),
    text: "Trophies",
    id: "BRT070",
  },

  {
    image: require('../assets/images/trophies/med1.jpg'),
    text: "Trophies",
    id: "MED001",
  },
  {
    image: require('../assets/images/trophies/med2.jpg'),
    text: "Trophies",
    id: "MED002",
  },
  {
    image: require('../assets/images/trophies/med3.jpg'),
    text: "Trophies",
    id: "MED003",
  },
  {
    image: require('../assets/images/trophies/med4.jpg'),
    text: "Trophies",
    id: "MED004",
  },
  {
    image: require('../assets/images/trophies/med5.jpg'),
    text: "Trophies",
    id: "MED005",
  },
  {
    image: require('../assets/images/trophies/med6.jpg'),
    text: "Trophies",
    id: "MED006",
  },
  {
    image: require('../assets/images/trophies/med7.jpg'),
    text: "Trophies",
    id: "MED007",
  },
  {
    image: require('../assets/images/trophies/med8.jpg'),
    text: "Trophies",
    id: "MED008",
  },
  {
    image: require('../assets/images/trophies/med9.jpg'),
    text: "Trophies",
    id: "MED009",
  },
  {
    image: require('../assets/images/trophies/med10.jpg'),
    text: "Trophies",
    id: "MED010",
  },
  {
    image: require('../assets/images/trophies/med11.jpg'),
    text: "Trophies",
    id: "MED011",
  },
  {
    image: require('../assets/images/trophies/med12.jpg'),
    text: "Trophies",
    id: "MED012",
  },
  {
    image: require('../assets/images/trophies/med13.jpg'),
    text: "Trophies",
    id: "MED013",
  },
  {
    image: require('../assets/images/trophies/med14.jpg'),
    text: "Trophies",
    id: "MED014",
  },
  {
    image: require('../assets/images/trophies/med15.jpg'),
    text: "Trophies",
    id: "MED015",
  },
  {
    image: require('../assets/images/trophies/med16.jpg'),
    text: "Trophies",
    id: "MED016",
  },
  {
    image: require('../assets/images/trophies/med17.jpg'),
    text: "Trophies",
    id: "MED017",
  },
  {
    image: require('../assets/images/trophies/med18.jpg'),
    text: "Trophies",
    id: "MED018",
  },
  {
    image: require('../assets/images/trophies/med19.jpg'),
    text: "Trophies",
    id: "MED019",
  },
  {
    image: require('../assets/images/trophies/med20.jpg'),
    text: "Trophies",
    id: "MED020",
  },
  {
    image: require('../assets/images/trophies/med21.jpg'),
    text: "Trophies",
    id: "MED021",
  },
  {
    image: require('../assets/images/trophies/med22.jpg'),
    text: "Trophies",
    id: "MED022",
  },
  {
    image: require('../assets/images/trophies/med23.jpg'),
    text: "Trophies",
    id: "MED023",
  },
  {
    image: require('../assets/images/trophies/med24.jpg'),
    text: "Trophies",
    id: "MED024",
  },
];

export const others = [
  {
    image: require('../assets/images/others/gif1.jpg'),
    text: "Tabletop",
    id: "GIF001",
  },
  {
    image: require('../assets/images/others/gif2.jpg'),
    text: "Tabletop",
    id: "GIF002",
  },
  {
    image: require('../assets/images/others/gif3.jpg'),
    text: "Tabletop",
    id: "GIF003",
  },
  {
    image: require('../assets/images/others/gif4.jpg'),
    text: "Tabletop",
    id: "GIF004",
  },
  {
    image: require('../assets/images/others/gif5.jpg'),
    text: "Tabletop",
    id: "GIF005",
  },
  {
    image: require('../assets/images/others/gif6.jpg'),
    text: "Tabletop",
    id: "GIF006",
  },
  {
    image: require('../assets/images/others/gif7.jpg'),
    text: "Tabletop",
    id: "GIF007",
  },
  {
    image: require('../assets/images/others/gif8.jpg'),
    text: "Tabletop",
    id: "GIF008",
  },
  {
    image: require('../assets/images/others/gif9.jpg'),
    text: "Tabletop",
    id: "GIF009",
  },
  {
    image: require('../assets/images/others/gif10.jpg'),
    text: "Tabletop",
    id: "GIF010",
  },
  {
    image: require('../assets/images/others/gif11.jpg'),
    text: "Tabletop",
    id: "GIF011",
  },
  {
    image: require('../assets/images/others/gif12.jpg'),
    text: "Tabletop",
    id: "GIF012",
  },
  {
    image: require('../assets/images/others/gif13.jpg'),
    text: "Tabletop",
    id: "GIF013",
  },
  {
    image: require('../assets/images/others/gif14.jpg'),
    text: "Tabletop",
    id: "GIF014",
  },
  {
    image: require('../assets/images/others/bad1.jpg'),
    text: "Tabletop",
    id: "BAD001",
  },
  {
    image: require('../assets/images/others/bad2.jpg'),
    text: "Tabletop",
    id: "BAD002",
  },
  {
    image: require('../assets/images/others/bad3.jpg'),
    text: "Tabletop",
    id: "BAD003",
  },
  {
    image: require('../assets/images/others/bad4.jpg'),
    text: "Tabletop",
    id: "BAD004",
  },
  {
    image: require('../assets/images/others/bad5.jpg'),
    text: "Tabletop",
    id: "BAD005",
  },
  {
    image: require('../assets/images/others/bad6.jpg'),
    text: "Tabletop",
    id: "BAD006",
  },
  {
    image: require('../assets/images/others/bad7.jpg'),
    text: "Tabletop",
    id: "BAD007",
  },
  {
    image: require('../assets/images/others/bad8.jpg'),
    text: "Tabletop",
    id: "BAD008",
  },
  {
    image: require('../assets/images/others/bad9.jpg'),
    text: "Tabletop",
    id: "BAD009",
  },
  {
    image: require('../assets/images/others/bad10.jpg'),
    text: "Tabletop",
    id: "BAD010",
  },
  {
    image: require('../assets/images/others/bad11.jpg'),
    text: "Tabletop",
    id: "BAD011",
  },
  {
    image: require('../assets/images/others/bad12.jpg'),
    text: "Tabletop",
    id: "BAD012",
  },
  {
    image: require('../assets/images/others/bad13.jpg'),
    text: "Tabletop",
    id: "BAD013",
  },
  {
    image: require('../assets/images/others/bad14.jpg'),
    text: "Tabletop",
    id: "BAD014",
  },
  {
    image: require('../assets/images/others/bad15.jpg'),
    text: "Tabletop",
    id: "BAD015",
  },
  {
    image: require('../assets/images/others/bad16.jpg'),
    text: "Tabletop",
    id: "BAD016",
  },
  {
    image: require('../assets/images/others/bad17.jpg'),
    text: "Tabletop",
    id: "BAD017",
  },
  {
    image: require('../assets/images/others/bad18.jpg'),
    text: "Tabletop",
    id: "BAD018",
  },
  {
    image: require('../assets/images/others/bad19.jpg'),
    text: "Tabletop",
    id: "BAD019",
  },
  {
    image: require('../assets/images/others/bad20.jpg'),
    text: "Tabletop",
    id: "BAD020",
  },
  {
    image: require('../assets/images/others/bad21.jpg'),
    text: "Tabletop",
    id: "BAD021",
  },
  {
    image: require('../assets/images/others/bad22.jpg'),
    text: "Tabletop",
    id: "BAD022",
  },
  {
    image: require('../assets/images/others/oth1.jpg'),
    text: "Tabletop",
    id: "OTH001",
  },
  {
    image: require('../assets/images/others/oth2.jpg'),
    text: "Tabletop",
    id: "OTH002",
  },
  {
    image: require('../assets/images/others/oth3.jpg'),
    text: "Tabletop",
    id: "OTH003",
  },
  {
    image: require('../assets/images/others/oth4.jpg'),
    text: "Tabletop",
    id: "OTH004",
  },
  {
    image: require('../assets/images/others/oth5.jpg'),
    text: "Tabletop",
    id: "OTH005",
  },
  {
    image: require('../assets/images/others/oth6.jpg'),
    text: "Tabletop",
    id: "OTH006",
  },
  {
    image: require('../assets/images/others/oth7.jpg'),
    text: "Tabletop",
    id: "OTH007",
  },
  {
    image: require('../assets/images/others/oth8.jpg'),
    text: "Tabletop",
    id: "OTH008",
  },
  {
    image: require('../assets/images/others/oth9.jpg'),
    text: "Tabletop",
    id: "OTH009",
  },
  {
    image: require('../assets/images/others/oth10.jpg'),
    text: "Tabletop",
    id: "OTH010",
  },
  {
    image: require('../assets/images/others/oth11.jpg'),
    text: "Tabletop",
    id: "OTH011",
  },
  {
    image: require('../assets/images/others/oth12.jpg'),
    text: "Tabletop",
    id: "OTH012",
  },
  {
    image: require('../assets/images/others/oth13.jpg'),
    text: "Tabletop",
    id: "OTH013",
  },
  {
    image: require('../assets/images/others/oth14.jpg'),
    text: "Tabletop",
    id: "OTH014",
  },
  {
    image: require('../assets/images/others/oth15.jpg'),
    text: "Tabletop",
    id: "OTH015",
  },
  {
    image: require('../assets/images/others/oth16.jpg'),
    text: "Tabletop",
    id: "OTH016",
  },
  {
    image: require('../assets/images/others/oth17.jpg'),
    text: "Tabletop",
    id: "OTH017",
  },
  {
    image: require('../assets/images/others/oth18.jpg'),
    text: "Tabletop",
    id: "OTH018",
  },
  {
    image: require('../assets/images/others/oth19.jpg'),
    text: "Tabletop",
    id: "OTH019",
  },
  {
    image: require('../assets/images/others/oth21.jpg'),
    text: "Tabletop",
    id: "OTH021",
  },

  {
    image: require('../assets/images/others/dia1.jpg'),
    text: "Tabletop",
    id: "DIA001",
  },
  {
    image: require('../assets/images/others/dia2.jpg'),
    text: "Tabletop",
    id: "DIA002",
  },
  {
    image: require('../assets/images/others/dia3.jpg'),
    text: "Tabletop",
    id: "DIA003",
  },
  {
    image: require('../assets/images/others/dia4.jpg'),
    text: "Tabletop",
    id: "DIA004",
  },
  {
    image: require('../assets/images/others/mug1.jpg'),
    text: "Tabletop",
    id: "MUG001",
  },
  {
    image: require('../assets/images/others/mug2.jpg'),
    text: "Tabletop",
    id: "MUG002",
  },
  {
    image: require('../assets/images/others/mug3.jpg'),
    text: "Tabletop",
    id: "MUG003",
  },
  {
    image: require('../assets/images/others/mug4.jpg'),
    text: "Tabletop",
    id: "MUG004",
  },
  {
    image: require('../assets/images/others/mug5.jpg'),
    text: "Tabletop",
    id: "MUG005",
  },
  {
    image: require('../assets/images/others/mug6.jpg'),
    text: "Tabletop",
    id: "MUG006",
  },
  {
    image: require('../assets/images/others/mug7.jpg'),
    text: "Tabletop",
    id: "MUG007",
  },
  {
    image: require('../assets/images/others/mug8.jpg'),
    text: "Tabletop",
    id: "MUG008",
  },
  {
    image: require('../assets/images/others/mug9.jpg'),
    text: "Tabletop",
    id: "MUG009",
  },
  {
    image: require('../assets/images/others/mug10.jpg'),
    text: "Tabletop",
    id: "MUG010",
  },
  {
    image: require('../assets/images/others/mug11.jpg'),
    text: "Tabletop",
    id: "MUG011",
  },
  {
    image: require('../assets/images/others/mug12.jpg'),
    text: "Tabletop",
    id: "MUG012",
  },
  {
    image: require('../assets/images/others/mug13.jpg'),
    text: "Tabletop",
    id: "MUG013",
  },
  {
    image: require('../assets/images/others/mug14.jpg'),
    text: "Tabletop",
    id: "MUG014",
  },
  {
    image: require('../assets/images/others/mug15.jpg'),
    text: "Tabletop",
    id: "MUG015",
  },
  {
    image: require('../assets/images/others/mug16.jpg'),
    text: "Tabletop",
    id: "MUG016",
  },
  {
    image: require('../assets/images/others/mug17.jpg'),
    text: "Tabletop",
    id: "MUG017",
  },
  {
    image: require('../assets/images/others/mug18.jpg'),
    text: "Tabletop",
    id: "MUG018",
  },
  {
    image: require('../assets/images/others/mug19.jpg'),
    text: "Tabletop",
    id: "MUG019",
  },
  {
    image: require('../assets/images/others/mug20.jpg'),
    text: "Tabletop",
    id: "MUG020",
  },
  {
    image: require('../assets/images/others/mug21.jpg'),
    text: "Tabletop",
    id: "MUG021",
  },
  {
    image: require('../assets/images/others/mug22.jpg'),
    text: "Tabletop",
    id: "MUG022",
  },
  {
    image: require('../assets/images/others/mug23.jpg'),
    text: "Tabletop",
    id: "MUG023",
  },
  {
    image: require('../assets/images/others/mug24.jpg'),
    text: "Tabletop",
    id: "MUG024",
  },
  {
    image: require('../assets/images/others/mug25.jpg'),
    text: "Tabletop",
    id: "MUG025",
  },
  {
    image: require('../assets/images/others/mug26.jpg'),
    text: "Tabletop",
    id: "MUG026",
  },
  {
    image: require('../assets/images/others/peke1.jpg'),
    text: "Tabletop",
    id: "PEKE001",
  },
  {
    image: require('../assets/images/others/peke2.jpg'),
    text: "Tabletop",
    id: "PEKE002",
  },
  {
    image: require('../assets/images/others/peke3.jpg'),
    text: "Tabletop",
    id: "PEKE003",
  },
  {
    image: require('../assets/images/others/peke4.jpg'),
    text: "Tabletop",
    id: "PEKE004",
  },
  {
    image: require('../assets/images/others/peke5.jpg'),
    text: "Tabletop",
    id: "PEKE005",
  },
  {
    image: require('../assets/images/others/peke6.jpg'),
    text: "Tabletop",
    id: "PEKE006",
  },
  {
    image: require('../assets/images/others/peke7.jpg'),
    text: "Tabletop",
    id: "PEKE007",
  },
  {
    image: require('../assets/images/others/peke8.jpg'),
    text: "Tabletop",
    id: "PEKE008",
  },
  {
    image: require('../assets/images/others/peke9.jpg'),
    text: "Tabletop",
    id: "PEKE009",
  },
  {
    image: require('../assets/images/others/peke10.jpg'),
    text: "Tabletop",
    id: "PEKE010",
  },
  {
    image: require('../assets/images/others/peke11.jpg'),
    text: "Tabletop",
    id: "PEKE011",
  },
  {
    image: require('../assets/images/others/peke12.jpg'),
    text: "Tabletop",
    id: "PEKE012",
  },
  {
    image: require('../assets/images/others/peke13.jpg'),
    text: "Tabletop",
    id: "PEKE013",
  },
  {
    image: require('../assets/images/others/peke14.jpg'),
    text: "Tabletop",
    id: "PEKE014",
  },
  {
    image: require('../assets/images/others/peke15.jpg'),
    text: "Tabletop",
    id: "PEKE015",
  },
  {
    image: require('../assets/images/others/peke16.jpg'),
    text: "Tabletop",
    id: "PEKE016",
  },
  {
    image: require('../assets/images/others/peke17.jpg'),
    text: "Tabletop",
    id: "PEKE017",
  },
  {
    image: require('../assets/images/others/peke18.jpg'),
    text: "Tabletop",
    id: "PEKE018",
  },
  {
    image: require('../assets/images/others/peke19.jpg'),
    text: "Tabletop",
    id: "PEKE019",
  },
  {
    image: require('../assets/images/others/peke20.jpg'),
    text: "Tabletop",
    id: "PEKE020",
  },
  {
    image: require('../assets/images/others/peke21.jpg'),
    text: "Tabletop",
    id: "PEKE021",
  },
  {
    image: require('../assets/images/others/peke22.jpg'),
    text: "Tabletop",
    id: "PEKE022",
  },
  {
    image: require('../assets/images/others/peke23.jpg'),
    text: "Tabletop",
    id: "PEKE023",
  },
  {
    image: require('../assets/images/others/peke24.jpg'),
    text: "Tabletop",
    id: "PEKE024",
  },
  {
    image: require('../assets/images/others/peke25.jpg'),
    text: "Tabletop",
    id: "PEKE025",
  },
  {
    image: require('../assets/images/others/peke26.jpg'),
    text: "Tabletop",
    id: "PEKE026",
  },
  {
    image: require('../assets/images/others/peke27.jpg'),
    text: "Tabletop",
    id: "PEKE027",
  },
  {
    image: require('../assets/images/others/peke28.jpg'),
    text: "Tabletop",
    id: "PEKE028",
  },
  {
    image: require('../assets/images/others/peke29.jpg'),
    text: "Tabletop",
    id: "PEKE029",
  },
  {
    image: require('../assets/images/others/peke30.jpg'),
    text: "Tabletop",
    id: "PEKE030",
  },
  {
    image: require('../assets/images/others/peke31.jpg'),
    text: "Tabletop",
    id: "PEKE031",
  },
  {
    image: require('../assets/images/others/peke32.jpg'),
    text: "Tabletop",
    id: "PEKE032",
  },
  {
    image: require('../assets/images/others/peke33.jpg'),
    text: "Tabletop",
    id: "PEKE033",
  },
  {
    image: require('../assets/images/others/peke34.jpg'),
    text: "Tabletop",
    id: "PEKE034",
  },
  {
    image: require('../assets/images/others/peke35.jpg'),
    text: "Tabletop",
    id: "PEKE035",
  },
  {
    image: require('../assets/images/others/peke36.jpg'),
    text: "Tabletop",
    id: "PEKE036",
  },
  {
    image: require('../assets/images/others/peke37.jpg'),
    text: "Tabletop",
    id: "PEKE037",
  },
  {
    image: require('../assets/images/others/peke38.jpg'),
    text: "Tabletop",
    id: "PEKE038",
  },
]

export const cap = [
  {
    image: require('../assets/images/cap/cap1.png'),
    text: "Caps",
    id: "CAP001",
  },
  {
    image: require('../assets/images/cap/cap2.png'),
    text: "Caps",
    id: "CAP002",
  },
  {
    image: require('../assets/images/cap/cap3.png'),
    text: "Caps",
    id: "CAP003",
  },
  {
    image: require('../assets/images/cap/cap4.png'),
    text: "Caps",
    id: "CAP004",
  },
  {
    image: require('../assets/images/cap/cap5.png'),
    text: "Caps",
    id: "CAP005",
  },
  {
    image: require('../assets/images/cap/cap6.png'),
    text: "Caps",
    id: "CAP006",
  },
  {
    image: require('../assets/images/cap/cap7.png'),
    text: "Caps",
    id: "CAP007",
  },
  {
    image: require('../assets/images/cap/cap8.png'),
    text: "Caps",
    id: "CAP008",
  },
  {
    image: require('../assets/images/cap/cap9.png'),
    text: "Caps",
    id: "CAP009",
  },
]

export const shirt = [
  {
    image: require('../assets/images/shart/sh1.png'),
    text: "T-shirts",
    id: "TSH001",
  },
  {
    image: require('../assets/images/shart/sh2.png'),
    text: "T-shirts",
    id: "TSH002",
  },
  {
    image: require('../assets/images/shart/sh3.png'),
    text: "T-shirts",
    id: "TSH003",
  },
  {
    image: require('../assets/images/shart/sh4.png'),
    text: "T-shirts",
    id: "TSH004",
  },
  {
    image: require('../assets/images/shart/sh5.png'),
    text: "T-shirts",
    id: "TSH005",
  },
  {
    image: require('../assets/images/shart/sh6.png'),
    text: "T-shirts",
    id: "TSH006",
  },
  {
    image: require('../assets/images/shart/sh7.png'),
    text: "T-shirts",
    id: "TSH007",
  },
  {
    image: require('../assets/images/shart/sh8.png'),
    text: "T-shirts",
    id: "TSH008",
  },
  {
    image: require('../assets/images/shart/sh9.png'),
    text: "T-shirts",
    id: "TSH009",
  },
  {
    image: require('../assets/images/shart/sh10.png'),
    text: "T-shirts",
    id: "TSH0010",
  },
  {
    image: require('../assets/images/shart/sh11.png'),
    text: "T-shirts",
    id: "TSH0011",
  },
  {
    image: require('../assets/images/shart/sh12.png'),
    text: "T-shirts",
    id: "TSH0012",
  },
  {
    image: require('../assets/images/shart/sh13.png'),
    text: "T-shirts",
    id: "TSH0013",
  },
  {
    image: require('../assets/images/shart/sh14.png'),
    text: "T-shirts",
    id: "TSH0014",
  },
]

export const watches = [
  {
    image: require('../assets/images/watches/wat1.png'),
    text: "Watches",
    id: "WAT001",
  },
]