import React from 'react'
import {GiHighFive} from "react-icons/gi";

function HandIconWithText({text}) {
  return (
    <div className="flex items-center mt-2">
      <GiHighFive className="text-header_bg text-lg" />
    <text className="text-gray-900 cursor-pointer hover:text-header_bg truncate ml-4 text-lg">{text}</text>
    </div>
  )
}

export default HandIconWithText