import React, {useState, useEffect} from 'react'
import '../card.css'
import {MdOutlineAdd} from "react-icons/md";

import { tableTop, travel,trophies,others, cap,shirt, watches } from "../../utility/filterList";
import {useParams, Link} from "react-router-dom";

function ProductFilter() {
  const {id} = useParams()
  const filterList = [
    'All',
    'Bags',
    'Caps',
    'T-shirts',
    'Handicrafts',
    'Leather',
    'Tabletop',
    'Travel',
    'Trophies & Medals',
    'Watches',
    'Others',
  ]
    const productList = [
    {
      image: require('../../assets/images/thumb1.jpg'),
      text:"Bags",
      category:"Bag | Umbrella",
    },

    {
      image: require('../../assets/images/All/all2.jpg'),
      text:"Caps",
      category:"",
    },
    {
      image: require('../../assets/images/All/all3.jpg'),
      text:"Mugs",
      category:"",
    },
    {
      image: require('../../assets/images/All/all4.jpg'),
      text:"T-shirts",
      category:"",
    },
    {
      image: require('../../assets/images/All/all5.jpg'),
      text:"Leather",
      category:"Leather Items | Folders",
    },
    {
      image: require('../../assets/images/All/all6.jpg'),
      text:"Leather",
      category:"Leather Items | Folders",
    },
    {
      image: require('../../assets/images/All/all7.jpg'),
      text:"Leather",
      category:"Leather Items | Folders",
    },
    {
      image: require('../../assets/images/All/all8.jpg'),
      text:"Leather",
      category:"Leather Items | Folders",
    },
    {
      image: require('../../assets/images/All/all9.jpg'),
      text:"Tabletop",
      category:"Electronic Desktop | Wooden & Metal desktop | Electronic Goods",
    },
    {
      image: require('../../assets/images/All/all10.jpg'),
      text:"Tabletop",
      category:"Electronic Desktop | Wooden & Metal desktop | Electronic Goods",
    },
    {
      image: require('../../assets/images/All/all11.jpg'),
      text:"Tabletop",
      category:"Electronic Desktop | Wooden & Metal desktop | Electronic Goods",
    },
    {
      image: require('../../assets/images/All/all12.jpg'),
      text:"Watch & Clock",
      category:"",
    },
    {
      image: require('../../assets/images/All/all13.jpg'),
      text:"Travel",
      category:"Trolley suitcase | Jacket and rain coat",
    },
    {
      image: require('../../assets/images/All/all14.jpg'),
      text:"Travel",
      category:"Trolley suitcase | Jacket and rain coat",
    },
    {
      image: require('../../assets/images/All/all15.jpg'),
      text:"Travel",
      category:"Trolley suitcase | Jacket and rain coat",
    },
    {
      image: require('../../assets/images/All/all16.jpg'),
      text:"Travel",
      category:"Trolley suitcase | Jacket and rain coat",
    },
    {
      image: require('../../assets/images/All/all17.jpg'),
      text:"Trophies & Medals",
      category:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
    },
    {
      image: require('../../assets/images/All/all18.jpg'),
      text:"Trophies & Medals",
      category:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
    },
    {
      image: require('../../assets/images/All/all19.jpg'),
      text:"Trophies & Medals",
      category:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
    },
    {
      image: require('../../assets/images/All/all20.jpg'),
      text:"Trophies & Medals",
      category:"Acrylic | Brass | Crystal | Iseries | Wooden | Medals",
    },
    {
      image: require('../../assets/images/All/all21.jpg'),
      text:"Others",
      category:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
    },
    {
      image: require('../../assets/images/All/all22.jpg'),
      text:"Others",
      category:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
    },
    {
      image: require('../../assets/images/All/all23.jpg'),
      text:"Others",
      category:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
    },
    {
      image: require('../../assets/images/All/all24.jpg'),
      text:"Others",
      category:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
    }
  ]
  const bag = [
      {
      image: require('../../assets/images/bag/bag1.jpg'),
      text:"Bags",
      id:"BAG001",
    },
      {
      image: require('../../assets/images/bag/bag2.jpg'),
      text:"Bags",
      id:"BAG002",
    },
      {
      image: require('../../assets/images/bag/bag3.jpg'),
      text:"Bags",
      id:"BAG003",
    },
      {
      image: require('../../assets/images/bag/bag4.jpg'),
      text:"Bags",
      id:"BAG004",
    },
      {
      image: require('../../assets/images/bag/bag5.jpg'),
      text:"Bags",
      id:"BAG005",
    },
      {
      image: require('../../assets/images/bag/bag6.jpg'),
      text:"Bags",
      id:"BAG006",
    },
      {
      image: require('../../assets/images/bag/bag7.jpg'),
      text:"Bags",
      id:"BAG007",
    },
      {
      image: require('../../assets/images/bag/bag8.jpg'),
      text:"Bags",
      id:"BAG008",
    },
      {
      image: require('../../assets/images/bag/bag9.jpg'),
      text:"Bags",
      id:"BAG009",
    },
      {
      image: require('../../assets/images/bag/bag10.jpg'),
      text:"Bags",
      id:"BAG0010",
    },
      {
      image: require('../../assets/images/bag/bag11.jpg'),
      text:"Bags",
      id:"BAG0011",
    },
      {
      image: require('../../assets/images/bag/bag12.jpg'),
      text:"Bags",
      id:"BAG001",
    },
      {
      image: require('../../assets/images/bag/bag1.jpg'),
      text:"Bags",
      id:"BAG0012",
    },
      {
      image: require('../../assets/images/bag/bag13.jpg'),
      text:"Bags",
      id:"BAG0013",
    },
      {
      image: require('../../assets/images/bag/bag14.jpg'),
      text:"Bags",
      id:"BAG0014",
    },
      {
      image: require('../../assets/images/bag/bag15.jpg'),
      text:"Bags",
      id:"BAG0015",
    },
      {
      image: require('../../assets/images/bag/bag16.jpg'),
      text:"Bags",
      id:"BAG0016",
    },
      {
      image: require('../../assets/images/bag/bag17.jpg'),
      text:"Bags",
      id:"BAG0017",
    },
      {
      image: require('../../assets/images/bag/bag18.jpg'),
      text:"Bags",
      id:"BAG0018",
    },
      {
      image: require('../../assets/images/bag/bag19.jpg'),
      text:"Bags",
      id:"BAG0019",
    },
      {
      image: require('../../assets/images/bag/bag20.jpg'),
      text:"Bags",
      id:"BAG0020",
    },
      {
      image: require('../../assets/images/bag/bag21.jpg'),
      text:"Bags",
      id:"BAG0021",
    },
      {
      image: require('../../assets/images/bag/bag22.jpg'),
      text:"Bags",
      id:"BAG0022",
    },
      {
      image: require('../../assets/images/bag/bag23.jpg'),
      text:"Bags",
      id:"BAG0023",
    },
      {
      image: require('../../assets/images/bag/bag24.jpg'),
      text:"Bags",
      id:"BAG0024",
    },
      {
      image: require('../../assets/images/bag/bag25.jpg'),
      text:"Bags",
      id:"BAG0025",
    },
      {
      image: require('../../assets/images/bag/bag26.jpg'),
      text:"Bags",
      id:"BAG0026",
    },
      {
      image: require('../../assets/images/bag/bag27.jpg'),
      text:"Bags",
      id:"BAG0027",
    },
      {
      image: require('../../assets/images/bag/bag28.jpg'),
      text:"Bags",
      id:"BAG0028",
    },
      {
      image: require('../../assets/images/bag/bag29.jpg'),
      text:"Bags",
      id:"BAG0029",
    },
      {
      image: require('../../assets/images/bag/bag30.jpg'),
      text:"Bags",
      id:"BAG0030",
    },
      {
      image: require('../../assets/images/bag/bag31.jpg'),
      text:"Bags",
      id:"BAG0031",
    },
      {
      image: require('../../assets/images/bag/bag32.jpg'),
      text:"Bags",
      id:"BAG0032",
    },
      {
      image: require('../../assets/images/bag/bag33.jpg'),
      text:"Bags",
      id:"BAG0033",
    },
      {
      image: require('../../assets/images/bag/bag34.jpg'),
      text:"Bags",
      id:"BAG0034",
    },
      {
      image: require('../../assets/images/bag/bag35.jpg'),
      text:"Bags",
      id:"BAG0035",
    },
      {
      image: require('../../assets/images/bag/bag36.jpg'),
      text:"Bags",
      id:"BAG0036",
    },
      {
      image: require('../../assets/images/bag/bag37.jpg'),
      text:"Bags",
      id:"BAG0037",
    },
      {
      image: require('../../assets/images/bag/bag38.jpg'),
      text:"Bags",
      id:"BAG0038",
    },
      {
      image: require('../../assets/images/bag/bag39.jpg'),
      text:"Bags",
      id:"BAG0039",
    },
      {
      image: require('../../assets/images/bag/bag42.jpg'),
      text:"Bags",
      id:"BAG0042",
    },
      {
      image: require('../../assets/images/bag/bag43.jpg'),
      text:"Bags",
      id:"BAG0043",
    },
      {
      image: require('../../assets/images/bag/bag44.jpg'),
      text:"Bags",
      id:"BAG0044",
    },
      {
      image: require('../../assets/images/bag/bag45.jpg'),
      text:"Bags",
      id:"BAG0045",
    },
      {
      image: require('../../assets/images/bag/bag46.jpg'),
      text:"Bags",
      id:"BAG0046",
    },
      {
      image: require('../../assets/images/bag/bag47.jpg'),
      text:"Bags",
      id:"BAG0047",
    },
      {
      image: require('../../assets/images/bag/bag48.jpg'),
      text:"Bags",
      id:"BAG0048",
    },
      {
      image: require('../../assets/images/bag/bag49.jpg'),
      text:"Bags",
      id:"BAG0049",
    },
      {
      image: require('../../assets/images/bag/bag50.jpg'),
      text:"Bags",
      id:"BAG0050",
    },
      {
      image: require('../../assets/images/bag/bag51.jpg'),
      text:"Bags",
      id:"BAG0051",
    },
      {
      image: require('../../assets/images/bag/bag52.jpg'),
      text:"Bags",
      id:"BAG0052",
    },
      {
      image: require('../../assets/images/bag/bag53.jpg'),
      text:"Bags",
      id:"BAG0053",
    },
      {
      image: require('../../assets/images/bag/bag54.jpg'),
      text:"Bags",
      id:"BAG0054",
    },
      {
      image: require('../../assets/images/bag/bag55.jpg'),
      text:"Bags",
      id:"BAG0055",
    },
      {
      image: require('../../assets/images/bag/bag56.jpg'),
      text:"Bags",
      id:"BAG0056",
    },
      {
      image: require('../../assets/images/bag/umb1.jpg'),
      text:"Bags",
      id:"UMB001",
    },
      {
      image: require('../../assets/images/bag/umb2.jpg'),
      text:"Bags",
      id:"UMB002",
    },
      {
      image: require('../../assets/images/bag/umb3.jpg'),
      text:"Bags",
      id:"UMB003",
    },
      {
      image: require('../../assets/images/bag/umb4.jpg'),
      text:"Bags",
      id:"UMB004",
    },

  ]
  const handicrafts = [
      {
      image: require('../../assets/images/Handicrfts/han1.jpg'),
      text:"Handicrafts",
      id:"HAN001",
      },
      {
      image: require('../../assets/images/Handicrfts/han2.jpg'),
      text:"Handicrafts",
      id:"HAN002",
      },
      {
      image: require('../../assets/images/Handicrfts/han3.jpg'),
      text:"Handicrafts",
      id:"HAN003",
      },
      {
      image: require('../../assets/images/Handicrfts/han4.jpg'),
      text:"Handicrafts",
      id:"HAN004",
      },
      {
      image: require('../../assets/images/Handicrfts/han5.jpg'),
      text:"Handicrafts",
      id:"HAN005",
      },
      {
      image: require('../../assets/images/Handicrfts/han6.jpg'),
      text:"Handicrafts",
      id:"HAN006",
      },
      {
      image: require('../../assets/images/Handicrfts/han7.jpg'),
      text:"Handicrafts",
      id:"HAN007",
      },
      {
      image: require('../../assets/images/Handicrfts/han8.jpg'),
      text:"Handicrafts",
      id:"HAN008",
      },
      {
      image: require('../../assets/images/Handicrfts/han9.jpg'),
      text:"Handicrafts",
      id:"HAN009",
      },
      {
      image: require('../../assets/images/Handicrfts/han10.jpg'),
      text:"Handicrafts",
      id:"HAN0010",
      },
      {
      image: require('../../assets/images/Handicrfts/han11.jpg'),
      text:"Handicrafts",
      id:"HAN0011",
      },
      {
      image: require('../../assets/images/Handicrfts/han12.jpg'),
      text:"Handicrafts",
      id:"HAN0012",
      },
      {
      image: require('../../assets/images/Handicrfts/han13.jpg'),
      text:"Handicrafts",
      id:"HAN0013",
      },
      {
      image: require('../../assets/images/Handicrfts/han14.jpg'),
      text:"Handicrafts",
      id:"HAN0014",
      },
      {
      image: require('../../assets/images/Handicrfts/han15.jpg'),
      text:"Handicrafts",
      id:"HAN0015",
      },
      {
      image: require('../../assets/images/Handicrfts/han16.jpg'),
      text:"Handicrafts",
      id:"HAN0016",
      },
      {
      image: require('../../assets/images/Handicrfts/han17.jpg'),
      text:"Handicrafts",
      id:"HAN0017",
      },
      {
      image: require('../../assets/images/Handicrfts/han18.jpg'),
      text:"Handicrafts",
      id:"HAN0018",
      },
      {
      image: require('../../assets/images/Handicrfts/han19.jpg'),
      text:"Handicrafts",
      id:"HAN0019",
      },
      {
      image: require('../../assets/images/Handicrfts/han20.jpg'),
      text:"Handicrafts",
      id:"HAN0020",
      },
      {
      image: require('../../assets/images/Handicrfts/han21.jpg'),
      text:"Handicrafts",
      id:"HAN0021",
      },
      {
      image: require('../../assets/images/Handicrfts/han22.jpg'),
      text:"Handicrafts",
      id:"HAN0022",
      },
      {
      image: require('../../assets/images/Handicrfts/han23.jpg'),
      text:"Handicrafts",
      id:"HAN0023",
      },
      {
      image: require('../../assets/images/Handicrfts/han24.jpg'),
      text:"Handicrafts",
      id:"HAN0024",
      },
      {
      image: require('../../assets/images/Handicrfts/han25.jpg'),
      text:"Handicrafts",
      id:"HAN0025",
      },
      {
      image: require('../../assets/images/Handicrfts/han26.jpg'),
      text:"Handicrafts",
      id:"HAN0026",
      },
      {
      image: require('../../assets/images/Handicrfts/han27.jpg'),
      text:"Handicrafts",
      id:"HAN0027",
      },
      {
      image: require('../../assets/images/Handicrfts/han28.jpg'),
      text:"Handicrafts",
      id:"HAN0028",
      },
      {
      image: require('../../assets/images/Handicrfts/han29.jpg'),
      text:"Handicrafts",
      id:"HAN0029",
      },
      {
      image: require('../../assets/images/Handicrfts/han30.jpg'),
      text:"Handicrafts",
      id:"HAN0030",
      },
      {
      image: require('../../assets/images/Handicrfts/han30.jpg'),
      text:"Handicrafts",
      id:"HAN0030",
      },
      {
      image: require('../../assets/images/Handicrfts/han31.jpg'),
      text:"Handicrafts",
      id:"HAN0031",
      },
      {
      image: require('../../assets/images/Handicrfts/han32.jpg'),
      text:"Handicrafts",
      id:"HAN0032",
      },
      {
      image: require('../../assets/images/Handicrfts/han33.jpg'),
      text:"Handicrafts",
      id:"HAN0033",
      },
      {
      image: require('../../assets/images/Handicrfts/han34.jpg'),
      text:"Handicrafts",
      id:"HAN0034",
      },
      {
      image: require('../../assets/images/Handicrfts/han35.jpg'),
      text:"Handicrafts",
      id:"HAN0035",
      },
      {
      image: require('../../assets/images/Handicrfts/han36.jpg'),
      text:"Handicrafts",
      id:"HAN0036",
      },
      {
      image: require('../../assets/images/Handicrfts/han37.jpg'),
      text:"Handicrafts",
      id:"HAN0037",
      },
      {
      image: require('../../assets/images/Handicrfts/han38.jpg'),
      text:"Handicrafts",
      id:"HAN0038",
      },
      {
      image: require('../../assets/images/Handicrfts/han39.jpg'),
      text:"Handicrafts",
      id:"HAN0039",
      },
      {
      image: require('../../assets/images/Handicrfts/han40.jpg'),
      text:"Handicrafts",
      id:"HAN0040",
      },
      {
      image: require('../../assets/images/Handicrfts/han41.jpg'),
      text:"Handicrafts",
      id:"HAN0041",
      },
      {
      image: require('../../assets/images/Handicrfts/han42.jpg'),
      text:"Handicrafts",
      id:"HAN0042",
      },
      {
      image: require('../../assets/images/Handicrfts/han43.jpg'),
      text:"Handicrafts",
      id:"HAN0043",
      },
      {
      image: require('../../assets/images/Handicrfts/han44.jpg'),
      text:"Handicrafts",
      id:"HAN0044",
      },
      {
      image: require('../../assets/images/Handicrfts/han45.jpg'),
      text:"Handicrafts",
      id:"HAN0045",
      },
      {
      image: require('../../assets/images/Handicrfts/han46.jpg'),
      text:"Handicrafts",
      id:"HAN0046",
      },
      {
      image: require('../../assets/images/Handicrfts/han47.jpg'),
      text:"Handicrafts",
      id:"HAN0047",
      },
      {
      image: require('../../assets/images/Handicrfts/han48.jpg'),
      text:"Handicrafts",
      id:"HAN0048",
      },
      {
      image: require('../../assets/images/Handicrfts/han49.jpg'),
      text:"Handicrafts",
      id:"HAN0049",
      },
      {
      image: require('../../assets/images/Handicrfts/han50.jpg'),
      text:"Handicrafts",
      id:"HAN0050",
      },
      {
      image: require('../../assets/images/Handicrfts/han51.jpg'),
      text:"Handicrafts",
      id:"HAN0051",
      },
      {
      image: require('../../assets/images/Handicrfts/han52.jpg'),
      text:"Handicrafts",
      id:"HAN0052",
      },
      {
      image: require('../../assets/images/Handicrfts/han53.jpg'),
      text:"Handicrafts",
      id:"HAN0053",
      },
      {
      image: require('../../assets/images/Handicrfts/han54.jpg'),
      text:"Handicrafts",
      id:"HAN0054",
      },
      {
      image: require('../../assets/images/Handicrfts/han55.jpg'),
      text:"Handicrafts",
      id:"HAN0055",
      },
      {
      image: require('../../assets/images/Handicrfts/han56.jpg'),
      text:"Handicrafts",
      id:"HAN0056",
      },
      {
      image: require('../../assets/images/Handicrfts/han57.jpg'),
      text:"Handicrafts",
      id:"HAN0057",
      },
      {
      image: require('../../assets/images/Handicrfts/han58.jpg'),
      text:"Handicrafts",
      id:"HAN0058",
      },
      {
      image: require('../../assets/images/Handicrfts/han59.jpg'),
      text:"Handicrafts",
      id:"HAN0059",
      },
      {
      image: require('../../assets/images/Handicrfts/han60.jpg'),
      text:"Handicrafts",
      id:"HAN0060",
      },
      {
      image: require('../../assets/images/Handicrfts/han61.jpg'),
      text:"Handicrafts",
      id:"HAN0061",
      },
      {
      image: require('../../assets/images/Handicrfts/han62.jpg'),
      text:"Handicrafts",
      id:"HAN0062",
      },
      {
      image: require('../../assets/images/Handicrfts/han63.jpg'),
      text:"Handicrafts",
      id:"HAN0063",
      },
      {
      image: require('../../assets/images/Handicrfts/han64.jpg'),
      text:"Handicrafts",
      id:"HAN0064",
      },
      {
      image: require('../../assets/images/Handicrfts/han65.jpg'),
      text:"Handicrafts",
      id:"HAN0065",
      },
      {
      image: require('../../assets/images/Handicrfts/han66.jpg'),
      text:"Handicrafts",
      id:"HAN0066",
      },
      {
      image: require('../../assets/images/Handicrfts/han67.jpg'),
      text:"Handicrafts",
      id:"HAN0067",
      },
      {
      image: require('../../assets/images/Handicrfts/han68.jpg'),
      text:"Handicrafts",
      id:"HAN0068",
      },
      {
      image: require('../../assets/images/Handicrfts/han69.jpg'),
      text:"Handicrafts",
      id:"HAN0069",
      },
      {
      image: require('../../assets/images/Handicrfts/han70.jpg'),
      text:"Handicrafts",
      id:"HAN0070",
      },
      {
      image: require('../../assets/images/Handicrfts/han71.jpg'),
      text:"Handicrafts",
      id:"HAN0071",
      },
      {
      image: require('../../assets/images/Handicrfts/han72.jpg'),
      text:"Handicrafts",
      id:"HAN0072",
      },
      {
      image: require('../../assets/images/Handicrfts/han73.jpg'),
      text:"Handicrafts",
      id:"HAN0073",
      },
      {
      image: require('../../assets/images/Handicrfts/han74.jpg'),
      text:"Handicrafts",
      id:"HAN0074",
      },
      {
      image: require('../../assets/images/Handicrfts/han75.jpg'),
      text:"Handicrafts",
      id:"HAN0075",
      },
      {
      image: require('../../assets/images/Handicrfts/han76.jpg'),
      text:"Handicrafts",
      id:"HAN0076",
      },
      {
      image: require('../../assets/images/Handicrfts/han77.jpg'),
      text:"Handicrafts",
      id:"HAN0077",
      },
      {
      image: require('../../assets/images/Handicrfts/han78.jpg'),
      text:"Handicrafts",
      id:"HAN0078",
      },
      {
      image: require('../../assets/images/Handicrfts/han79.jpg'),
      text:"Handicrafts",
      id:"HAN0079",
      },
      {
      image: require('../../assets/images/Handicrfts/han80.jpg'),
      text:"Handicrafts",
      id:"HAN0080",
      },
      {
      image: require('../../assets/images/Handicrfts/han81.jpg'),
      text:"Handicrafts",
      id:"HAN0081",
      },
      {
      image: require('../../assets/images/Handicrfts/han82.jpg'),
      text:"Handicrafts",
      id:"HAN0082",
      },
      {
      image: require('../../assets/images/Handicrfts/han83.jpg'),
      text:"Handicrafts",
      id:"HAN0083",
      },
      {
      image: require('../../assets/images/Handicrfts/han84.jpg'),
      text:"Handicrafts",
      id:"HAN0084",
      },
      {
      image: require('../../assets/images/Handicrfts/han85.jpg'),
      text:"Handicrafts",
      id:"HAN0085",
      },
      {
      image: require('../../assets/images/Handicrfts/han86.jpg'),
      text:"Handicrafts",
      id:"HAN0086",
      },
      {
      image: require('../../assets/images/Handicrfts/han87.jpg'),
      text:"Handicrafts",
      id:"HAN0087",
      },
      {
      image: require('../../assets/images/Handicrfts/han88.jpg'),
      text:"Handicrafts",
      id:"HAN0088",
      },
      {
      image: require('../../assets/images/Handicrfts/han89.jpg'),
      text:"Handicrafts",
      id:"HAN0089",
      },
      {
      image: require('../../assets/images/Handicrfts/han8.jpg'),
      text:"Handicrafts",
      id:"HAN008",
      },
      {
      image: require('../../assets/images/Handicrfts/han90.jpg'),
      text:"Handicrafts",
      id:"HAN0090",
      },
      {
      image: require('../../assets/images/Handicrfts/han91.jpg'),
      text:"Handicrafts",
      id:"HAN0091",
      },
      {
      image: require('../../assets/images/Handicrfts/han92.jpg'),
      text:"Handicrafts",
      id:"HAN0092",
      },
      {
      image: require('../../assets/images/Handicrfts/han93.jpg'),
      text:"Handicrafts",
      id:"HAN0093",
      },
      {
      image: require('../../assets/images/Handicrfts/han94.jpg'),
      text:"Handicrafts",
      id:"HAN0094",
      },
      {
      image: require('../../assets/images/Handicrfts/han95.jpg'),
      text:"Handicrafts",
      id:"HAN0095",
      },
      {
      image: require('../../assets/images/Handicrfts/han96.jpg'),
      text:"Handicrafts",
      id:"HAN0096",
      },
      {
      image: require('../../assets/images/Handicrfts/han97.jpg'),
      text:"Handicrafts",
      id:"HAN0097",
      },
      {
      image: require('../../assets/images/Handicrfts/han98.jpg'),
      text:"Handicrafts",
      id:"HAN0098",
      },
      {
      image: require('../../assets/images/Handicrfts/han99.jpg'),
      text:"Handicrafts",
      id:"HAN0099",
      },
      {
      image: require('../../assets/images/Handicrfts/han100.jpg'),
      text:"Handicrafts",
      id:"HAN0100",
      },
      {
      image: require('../../assets/images/Handicrfts/han101.jpg'),
      text:"Handicrafts",
      id:"HAN0101",
      },
      {
      image: require('../../assets/images/Handicrfts/han102.jpg'),
      text:"Handicrafts",
      id:"HAN0102",
      },
      {
      image: require('../../assets/images/Handicrfts/han103.jpg'),
      text:"Handicrafts",
      id:"HAN0103",
      },
      {
      image: require('../../assets/images/Handicrfts/han104.jpg'),
      text:"Handicrafts",
      id:"HAN104",
      },
      {
      image: require('../../assets/images/Handicrfts/han105.jpg'),
      text:"Handicrafts",
      id:"HAN105",
      },
      {
      image: require('../../assets/images/Handicrfts/han106.jpg'),
      text:"Handicrafts",
      id:"HAN106",
      },
      {
      image: require('../../assets/images/Handicrfts/han107.jpg'),
      text:"Handicrafts",
      id:"HAN107",
      },
      {
      image: require('../../assets/images/Handicrfts/han108.jpg'),
      text:"Handicrafts",
      id:"HAN108",
      },
      {
      image: require('../../assets/images/Handicrfts/han109.jpg'),
      text:"Handicrafts",
      id:"HAN109",
      },
      {
      image: require('../../assets/images/Handicrfts/han110.jpg'),
      text:"Handicrafts",
      id:"HAN110",
      },
      {
      image: require('../../assets/images/Handicrfts/han111.jpg'),
      text:"Handicrafts",
      id:"HAN11",
      },
      {
      image: require('../../assets/images/Handicrfts/han11.jpg'),
      text:"Handicrafts",
      id:"HAN111",
      },
      {
      image: require('../../assets/images/Handicrfts/han112.jpg'),
      text:"Handicrafts",
      id:"HAN112",
      },
      {
      image: require('../../assets/images/Handicrfts/han113.jpg'),
      text:"Handicrafts",
      id:"HAN113",
      },
      {
      image: require('../../assets/images/Handicrfts/han114.jpg'),
      text:"Handicrafts",
      id:"HAN114",
      },
      {
      image: require('../../assets/images/Handicrfts/han115.jpg'),
      text:"Handicrafts",
      id:"HAN115",
      },
      {
      image: require('../../assets/images/Handicrfts/han116.jpg'),
      text:"Handicrafts",
      id:"HAN116",
      },
      {
      image: require('../../assets/images/Handicrfts/han117.jpg'),
      text:"Handicrafts",
      id:"HAN117",
      },
      {
      image: require('../../assets/images/Handicrfts/han118.jpg'),
      text:"Handicrafts",
      id:"HAN118",
      },
      {
      image: require('../../assets/images/Handicrfts/han119.jpg'),
      text:"Handicrafts",
      id:"HAN119",
      },
      {
      image: require('../../assets/images/Handicrfts/han120.jpg'),
      text:"Handicrafts",
      id:"HAN120",
      },
      {
      image: require('../../assets/images/Handicrfts/han121.jpg'),
      text:"Handicrafts",
      id:"HAN121",
      },
      {
      image: require('../../assets/images/Handicrfts/han122.jpg'),
      text:"Handicrafts",
      id:"HAN122",
      },
      {
      image: require('../../assets/images/Handicrfts/han123.jpg'),
      text:"Handicrafts",
      id:"HAN123",
      },
      {
      image: require('../../assets/images/Handicrfts/han124.jpg'),
      text:"Handicrafts",
      id:"HAN124",
      },
      {
      image: require('../../assets/images/Handicrfts/han125.jpg'),
      text:"Handicrafts",
      id:"HAN125",
      },
      {
      image: require('../../assets/images/Handicrfts/han126.jpg'),
      text:"Handicrafts",
      id:"HAN126",
      },
      {
      image: require('../../assets/images/Handicrfts/han127.jpg'),
      text:"Handicrafts",
      id:"HAN127",
      },
      {
      image: require('../../assets/images/Handicrfts/han128.jpg'),
      text:"Handicrafts",
      id:"HAN128",
      },
      {
      image: require('../../assets/images/Handicrfts/han129.jpg'),
      text:"Handicrafts",
      id:"HAN129",
      },
      {
      image: require('../../assets/images/Handicrfts/han130.jpg'),
      text:"Handicrafts",
      id:"HAN130",
      },
      {
      image: require('../../assets/images/Handicrfts/han131.jpg'),
      text:"Handicrafts",
      id:"HAN131",
      },
      {
      image: require('../../assets/images/Handicrfts/han132.jpg'),
      text:"Handicrafts",
      id:"HAN132",
      },
      {
      image: require('../../assets/images/Handicrfts/han133.jpg'),
      text:"Handicrafts",
      id:"HAN133",
      },
      {
      image: require('../../assets/images/Handicrfts/han134.jpg'),
      text:"Handicrafts",
      id:"HAN134",
      },
      {
      image: require('../../assets/images/Handicrfts/han135.jpg'),
      text:"Handicrafts",
      id:"HAN135",
      },
      {
      image: require('../../assets/images/Handicrfts/han136.jpg'),
      text:"Handicrafts",
      id:"HAN136",
      },
      {
      image: require('../../assets/images/Handicrfts/han137.jpg'),
      text:"Handicrafts",
      id:"HAN137",
      },
      {
      image: require('../../assets/images/Handicrfts/han138.jpg'),
      text:"Handicrafts",
      id:"HAN138",
      },
      {
      image: require('../../assets/images/Handicrfts/han139.jpg'),
      text:"Handicrafts",
      id:"HAN139",
      },
      {
      image: require('../../assets/images/Handicrfts/han140.jpg'),
      text:"Handicrafts",
      id:"HAN140",
      },
      {
      image: require('../../assets/images/Handicrfts/han141.jpg'),
      text:"Handicrafts",
      id:"HAN141",
      },
      {
      image: require('../../assets/images/Handicrfts/han142.jpg'),
      text:"Handicrafts",
      id:"HAN142",
      },
      {
      image: require('../../assets/images/Handicrfts/han143.jpg'),
      text:"Handicrafts",
      id:"HAN143",
      },
      {
      image: require('../../assets/images/Handicrfts/han144.jpg'),
      text:"Handicrafts",
      id:"HAN144",
      },
      {
      image: require('../../assets/images/Handicrfts/han145.jpg'),
      text:"Handicrafts",
      id:"HAN145",
      },
      {
      image: require('../../assets/images/Handicrfts/han146.jpg'),
      text:"Handicrafts",
      id:"HAN146",
      },
      {
      image: require('../../assets/images/Handicrfts/han147.jpg'),
      text:"Handicrafts",
      id:"HAN147",
      },
      {
      image: require('../../assets/images/Handicrfts/han148.jpg'),
      text:"Handicrafts",
      id:"HAN148",
      },
      {
      image: require('../../assets/images/Handicrfts/han149.jpg'),
      text:"Handicrafts",
      id:"HAN149",
      },
      {
      image: require('../../assets/images/Handicrfts/han150.jpg'),
      text:"Handicrafts",
      id:"HAN150",
      },
      {
      image: require('../../assets/images/Handicrfts/han151.jpg'),
      text:"Handicrafts",
      id:"HAN152",
      },
      {
      image: require('../../assets/images/Handicrfts/han153.jpg'),
      text:"Handicrafts",
      id:"HAN153",
      },
      {
      image: require('../../assets/images/Handicrfts/han154.jpg'),
      text:"Handicrafts",
      id:"HAN154",
      },
      {
      image: require('../../assets/images/Handicrfts/han155.jpg'),
      text:"Handicrafts",
      id:"HAN155",
      },
      {
      image: require('../../assets/images/Handicrfts/han156.jpg'),
      text:"Handicrafts",
      id:"HAN156",
      },
      {
      image: require('../../assets/images/Handicrfts/han157.jpg'),
      text:"Handicrafts",
      id:"HAN157",
      },
      {
      image: require('../../assets/images/Handicrfts/han158.jpg'),
      text:"Handicrafts",
      id:"HAN158",
      },
      {
      image: require('../../assets/images/Handicrfts/han159.jpg'),
      text:"Handicrafts",
      id:"HAN159",
      },
      {
      image: require('../../assets/images/Handicrfts/han160.jpg'),
      text:"Handicrafts",
      id:"HAN160",
      },
      {
      image: require('../../assets/images/Handicrfts/han161.jpg'),
      text:"Handicrafts",
      id:"HAN161",
      },
      {
      image: require('../../assets/images/Handicrfts/han162.jpg'),
      text:"Handicrafts",
      id:"HAN162",
      },
      {
      image: require('../../assets/images/Handicrfts/han163.jpg'),
      text:"Handicrafts",
      id:"HAN163",
      },
      {
      image: require('../../assets/images/Handicrfts/han164.jpg'),
      text:"Handicrafts",
      id:"HAN164",
      },
      {
      image: require('../../assets/images/Handicrfts/han165.jpg'),
      text:"Handicrafts",
      id:"HAN165",
      },
      {
      image: require('../../assets/images/Handicrfts/han166.jpg'),
      text:"Handicrafts",
      id:"HAN166",
      },
      {
      image: require('../../assets/images/Handicrfts/han167.jpg'),
      text:"Handicrafts",
      id:"HAN167",
      },
      {
      image: require('../../assets/images/Handicrfts/han168.jpg'),
      text:"Handicrafts",
      id:"HAN168",
      },
      {
      image: require('../../assets/images/Handicrfts/han169.jpg'),
      text:"Handicrafts",
      id:"HAN169",
      },
      {
      image: require('../../assets/images/Handicrfts/han170.jpg'),
      text:"Handicrafts",
      id:"HAN170",
      },
      {
      image: require('../../assets/images/Handicrfts/han171.jpg'),
      text:"Handicrafts",
      id:"HAN171",
      },
      {
      image: require('../../assets/images/Handicrfts/han172.jpg'),
      text:"Handicrafts",
      id:"HAN172",
      },
      {
      image: require('../../assets/images/Handicrfts/han173.jpg'),
      text:"Handicrafts",
      id:"HAN173",
      },
      {
      image: require('../../assets/images/Handicrfts/han174.jpg'),
      text:"Handicrafts",
      id:"HAN174",
      },
      {
      image: require('../../assets/images/Handicrfts/han175.jpg'),
      text:"Handicrafts",
      id:"HAN175",
      },
      {
      image: require('../../assets/images/Handicrfts/han176.jpg'),
      text:"Handicrafts",
      id:"HAN176",
      },
      {
      image: require('../../assets/images/Handicrfts/han177.jpg'),
      text:"Handicrafts",
      id:"HAN177",
      },
      {
      image: require('../../assets/images/Handicrfts/han178.jpg'),
      text:"Handicrafts",
      id:"HAN178",
      },
      {
      image: require('../../assets/images/Handicrfts/han179.jpg'),
      text:"Handicrafts",
      id:"HAN179",
      },
      {
      image: require('../../assets/images/Handicrfts/han180.jpg'),
      text:"Handicrafts",
      id:"HAN180",
      },
      {
      image: require('../../assets/images/Handicrfts/han181.jpg'),
      text:"Handicrafts",
      id:"HAN181",
      },
      {
      image: require('../../assets/images/Handicrfts/han182.jpg'),
      text:"Handicrafts",
      id:"HAN182",
      },
      {
      image: require('../../assets/images/Handicrfts/han183.jpg'),
      text:"Handicrafts",
      id:"HAN183",
      },
      {
      image: require('../../assets/images/Handicrfts/han184.jpg'),
      text:"Handicrafts",
      id:"HAN184",
      },
      {
      image: require('../../assets/images/Handicrfts/han185.jpg'),
      text:"Handicrafts",
      id:"HAN185",
      },
      {
      image: require('../../assets/images/Handicrfts/han186.jpg'),
      text:"Handicrafts",
      id:"HAN186",
      },
      {
      image: require('../../assets/images/Handicrfts/han187.jpg'),
      text:"Handicrafts",
      id:"HAN187",
      },
      {
      image: require('../../assets/images/Handicrfts/han188.jpg'),
      text:"Handicrafts",
      id:"HAN188",
      },
      {
      image: require('../../assets/images/Handicrfts/han189.jpg'),
      text:"Handicrafts",
      id:"HAN189",
      },
      {
      image: require('../../assets/images/Handicrfts/han190.jpg'),
      text:"Handicrafts",
      id:"HAN190",
      },
      {
      image: require('../../assets/images/Handicrfts/han191.jpg'),
      text:"Handicrafts",
      id:"HAN191",
      },
      {
      image: require('../../assets/images/Handicrfts/han192.jpg'),
      text:"Handicrafts",
      id:"HAN192",
      },
      {
      image: require('../../assets/images/Handicrfts/han193.jpg'),
      text:"Handicrafts",
      id:"HAN193",
      },
      {
      image: require('../../assets/images/Handicrfts/han194.jpg'),
      text:"Handicrafts",
      id:"HAN194",
      },
      {
      image: require('../../assets/images/Handicrfts/han195.jpg'),
      text:"Handicrafts",
      id:"HAN195",
      },
      {
      image: require('../../assets/images/Handicrfts/han196.jpg'),
      text:"Handicrafts",
      id:"HAN196",
      },
      {
      image: require('../../assets/images/Handicrfts/han197.jpg'),
      text:"Handicrafts",
      id:"HAN197",
      },
      {
      image: require('../../assets/images/Handicrfts/han198.jpg'),
      text:"Handicrafts",
      id:"HAN198",
      },
      {
      image: require('../../assets/images/Handicrfts/han199.jpg'),
      text:"Handicrafts",
      id:"HAN199",
      },
      {
      image: require('../../assets/images/Handicrfts/han200.jpg'),
      text:"Handicrafts",
      id:"HAN200",
      },
      {
      image: require('../../assets/images/Handicrfts/han201.jpg'),
      text:"Handicrafts",
      id:"HAN201",
      },
      {
      image: require('../../assets/images/Handicrfts/han202.jpg'),
      text:"Handicrafts",
      id:"HAN202",
      },
      {
      image: require('../../assets/images/Handicrfts/han203.jpg'),
      text:"Handicrafts",
      id:"HAN203",
      },
      {
      image: require('../../assets/images/Handicrfts/han204.jpg'),
      text:"Handicrafts",
      id:"HAN204",
      },
      {
      image: require('../../assets/images/Handicrfts/han205.jpg'),
      text:"Handicrafts",
      id:"HAN205",
      },
      {
      image: require('../../assets/images/Handicrfts/han206.jpg'),
      text:"Handicrafts",
      id:"HAN206",
      },
      {
      image: require('../../assets/images/Handicrfts/han207.jpg'),
      text:"Handicrafts",
      id:"HAN207",
      },
      {
      image: require('../../assets/images/Handicrfts/han208.jpg'),
      text:"Handicrafts",
      id:"HAN208",
      },
      {
      image: require('../../assets/images/Handicrfts/han209.jpg'),
      text:"Handicrafts",
      id:"HAN209",
      },
      {
      image: require('../../assets/images/Handicrfts/han210.jpg'),
      text:"Handicrafts",
      id:"HAN210",
      },
      {
      image: require('../../assets/images/Handicrfts/han211.jpg'),
      text:"Handicrafts",
      id:"HAN211",
      },
      {
      image: require('../../assets/images/Handicrfts/han212.jpg'),
      text:"Handicrafts",
      id:"HAN212",
      },
      {
      image: require('../../assets/images/Handicrfts/han213.jpg'),
      text:"Handicrafts",
      id:"HAN213",
      },
      {
      image: require('../../assets/images/Handicrfts/han214.jpg'),
      text:"Handicrafts",
      id:"HAN214",
      },
      {
      image: require('../../assets/images/Handicrfts/han215.jpg'),
      text:"Handicrafts",
      id:"HAN215",
      },
      {
      image: require('../../assets/images/Handicrfts/han216.jpg'),
      text:"Handicrafts",
      id:"HAN216",
      },
      {
      image: require('../../assets/images/Handicrfts/han217.jpg'),
      text:"Handicrafts",
      id:"HAN217",
      },
      {
      image: require('../../assets/images/Handicrfts/han218.jpg'),
      text:"Handicrafts",
      id:"HAN218",
      },
      {
      image: require('../../assets/images/Handicrfts/han219.jpg'),
      text:"Handicrafts",
      id:"HAN219",
      },
      {
      image: require('../../assets/images/Handicrfts/han220.jpg'),
      text:"Handicrafts",
      id:"HAN220",
      },
      {
      image: require('../../assets/images/Handicrfts/han221.jpg'),
      text:"Handicrafts",
      id:"HAN221",
      },
      {
      image: require('../../assets/images/Handicrfts/han222.jpg'),
      text:"Handicrafts",
      id:"HAN22",
      },

    


  ]
  const leather = [
     {
      image: require('../../assets/images/leather/lel1.jpg'),
      text:"Leather",
      id:"LEI001",
      },
     {
      image: require('../../assets/images/leather/lel2.jpg'),
      text:"Leather",
      id:"LEI002",
      },
     {
      image: require('../../assets/images/leather/lel3.jpg'),
      text:"Leather",
      id:"LEI003",
      },
     {
      image: require('../../assets/images/leather/lel4.jpg'),
      text:"Leather",
      id:"LEI004",
      },
     {
      image: require('../../assets/images/leather/lel5.jpg'),
      text:"Leather",
      id:"LEI005",
      },
     {
      image: require('../../assets/images/leather/lel6.jpg'),
      text:"Leather",
      id:"LEI006",
      },
     {
      image: require('../../assets/images/leather/lel7.jpg'),
      text:"Leather",
      id:"LEI007",
      },
     {
      image: require('../../assets/images/leather/lel8.jpg'),
      text:"Leather",
      id:"LEI008",
      },
     {
      image: require('../../assets/images/leather/lel9.jpg'),
      text:"Leather",
      id:"LEI009",
      },
     {
      image: require('../../assets/images/leather/lel10.jpg'),
      text:"Leather",
      id:"LEI010",
      },
     {
      image: require('../../assets/images/leather/lel11.jpg'),
      text:"Leather",
      id:"LEI011",
      },
     {
      image: require('../../assets/images/leather/lel12.jpg'),
      text:"Leather",
      id:"LEI012",
      },
     {
      image: require('../../assets/images/leather/lel13.jpg'),
      text:"Leather",
      id:"LEI013",
      },
     {
      image: require('../../assets/images/leather/lel14.jpg'),
      text:"Leather",
      id:"LEI014",
      },
     {
      image: require('../../assets/images/leather/lel15.jpg'),
      text:"Leather",
      id:"LEI015",
      },
     {
      image: require('../../assets/images/leather/lel16.jpg'),
      text:"Leather",
      id:"LEI016",
      },
     {
      image: require('../../assets/images/leather/lel17.jpg'),
      text:"Leather",
      id:"LEI017",
      },
     {
      image: require('../../assets/images/leather/lel18.jpg'),
      text:"Leather",
      id:"LEI018",
      },
     {
      image: require('../../assets/images/leather/lel19.jpg'),
      text:"Leather",
      id:"LEI019",
      },
     {
      image: require('../../assets/images/leather/lel20.jpg'),
      text:"Leather",
      id:"LEI020",
      },
     {
      image: require('../../assets/images/leather/lel21.jpg'),
      text:"Leather",
      id:"LEI021",
      },
     {
      image: require('../../assets/images/leather/lel22.jpg'),
      text:"Leather",
      id:"LEI022",
      },
     {
      image: require('../../assets/images/leather/lel23.jpg'),
      text:"Leather",
      id:"LEI023",
      },
     {
      image: require('../../assets/images/leather/lel24.jpg'),
      text:"Leather",
      id:"LEI024",
      },
     {
      image: require('../../assets/images/leather/lel25.jpg'),
      text:"Leather",
      id:"LEI025",
      },
     {
      image: require('../../assets/images/leather/lel26.jpg'),
      text:"Leather",
      id:"LEI026",
      },
     {
      image: require('../../assets/images/leather/lel27.jpg'),
      text:"Leather",
      id:"LEI027",
      },
     {
      image: require('../../assets/images/leather/lel28.jpg'),
      text:"Leather",
      id:"LEI028",
      },
     {
      image: require('../../assets/images/leather/lel29.jpg'),
      text:"Leather",
      id:"LEI029",
      },
     {
      image: require('../../assets/images/leather/lel30.jpg'),
      text:"Leather",
      id:"LEI030",
      },
     {
      image: require('../../assets/images/leather/lel31.jpg'),
      text:"Leather",
      id:"LEI031",
      },
     {
      image: require('../../assets/images/leather/lel32.jpg'),
      text:"Leather",
      id:"LEI032",
      },
     {
      image: require('../../assets/images/leather/lel33.jpg'),
      text:"Leather",
      id:"LEI033",
      },
     {
      image: require('../../assets/images/leather/lel34.jpg'),
      text:"Leather",
      id:"LEI034",
      },
     {
      image: require('../../assets/images/leather/lel35.jpg'),
      text:"Leather",
      id:"LEI035",
      },
     {
      image: require('../../assets/images/leather/lel36.jpg'),
      text:"Leather",
      id:"LEI036",
      },
     {
      image: require('../../assets/images/leather/lel37.jpg'),
      text:"Leather",
      id:"LEI037",
      },
     {
      image: require('../../assets/images/leather/lel38.jpg'),
      text:"Leather",
      id:"LEI038",
      },
     {
      image: require('../../assets/images/leather/lel39.jpg'),
      text:"Leather",
      id:"LEI039",
      },
     {
      image: require('../../assets/images/leather/lel40.jpg'),
      text:"Leather",
      id:"LEI040",
      },
     {
      image: require('../../assets/images/leather/lel41.jpg'),
      text:"Leather",
      id:"LEI041",
      },
     {
      image: require('../../assets/images/leather/lel42.jpg'),
      text:"Leather",
      id:"LEI042",
      },
     {
      image: require('../../assets/images/leather/lel43.jpg'),
      text:"Leather",
      id:"LEI043",
      },
     {
      image: require('../../assets/images/leather/lel44.jpg'),
      text:"Leather",
      id:"LEI044",
      },
     {
      image: require('../../assets/images/leather/lel45.jpg'),
      text:"Leather",
      id:"LEI045",
      },
     {
      image: require('../../assets/images/leather/lel46.jpg'),
      text:"Leather",
      id:"LEI046",
      },
     {
      image: require('../../assets/images/leather/lel47.jpg'),
      text:"Leather",
      id:"LEI047",
      },
     {
      image: require('../../assets/images/leather/lel48.jpg'),
      text:"Leather",
      id:"LEI048",
      },
     {
      image: require('../../assets/images/leather/lel49.jpg'),
      text:"Leather",
      id:"LEI049",
      },
     {
      image: require('../../assets/images/leather/lel50.jpg'),
      text:"Leather",
      id:"LEI050",
      },
     {
      image: require('../../assets/images/leather/lel51.jpg'),
      text:"Leather",
      id:"LEI051",
      },
     {
      image: require('../../assets/images/leather/lel52.jpg'),
      text:"Leather",
      id:"LEI052",
      },
     {
      image: require('../../assets/images/leather/lel53.jpg'),
      text:"Leather",
      id:"LEI053",
      },
     {
      image: require('../../assets/images/leather/lel54.jpg'),
      text:"Leather",
      id:"LEI054",
      },
     {
      image: require('../../assets/images/leather/lel55.jpg'),
      text:"Leather",
      id:"LEI055",
      },
     {
      image: require('../../assets/images/leather/lel56.jpg'),
      text:"Leather",
      id:"LEI056",
      },
     {
      image: require('../../assets/images/leather/lel57.jpg'),
      text:"Leather",
      id:"LEI057",
      },
     {
      image: require('../../assets/images/leather/lel58.jpg'),
      text:"Leather",
      id:"LEI058",
      },
     {
      image: require('../../assets/images/leather/lel59.jpg'),
      text:"Leather",
      id:"LEI059",
      },
     {
      image: require('../../assets/images/leather/lel60.jpg'),
      text:"Leather",
      id:"LEI060",
      },
     {
      image: require('../../assets/images/leather/lel61.jpg'),
      text:"Leather",
      id:"LEI061",
      },
     {
      image: require('../../assets/images/leather/lel62.jpg'),
      text:"Leather",
      id:"LEI062",
      },
     {
      image: require('../../assets/images/leather/lel63.jpg'),
      text:"Leather",
      id:"LEI063",
      },
     {
      image: require('../../assets/images/leather/lel64.jpg'),
      text:"Leather",
      id:"LEI064",
      },
     {
      image: require('../../assets/images/leather/lel65.jpg'),
      text:"Leather",
      id:"LEI065",
      },
     {
      image: require('../../assets/images/leather/lel66.jpg'),
      text:"Leather",
      id:"LEI066",
      },
     {
      image: require('../../assets/images/leather/lel67.jpg'),
      text:"Leather",
      id:"LEI067",
      },
     {
      image: require('../../assets/images/leather/lel68.jpg'),
      text:"Leather",
      id:"LEI068",
      },
     {
      image: require('../../assets/images/leather/lel69.jpg'),
      text:"Leather",
      id:"LEI069",
      },
     {
      image: require('../../assets/images/leather/lel70.jpg'),
      text:"Leather",
      id:"LEI070",
      },
     {
      image: require('../../assets/images/leather/lel71.jpg'),
      text:"Leather",
      id:"LEI071",
      },
     {
      image: require('../../assets/images/leather/lel72.jpg'),
      text:"Leather",
      id:"LEI072",
      },
     {
      image: require('../../assets/images/leather/lel73.jpg'),
      text:"Leather",
      id:"LEI073",
      },
     {
      image: require('../../assets/images/leather/lel74.jpg'),
      text:"Leather",
      id:"LEI074",
      },
     {
      image: require('../../assets/images/leather/lel75.jpg'),
      text:"Leather",
      id:"LEI075",
      },
     {
      image: require('../../assets/images/leather/lel76.jpg'),
      text:"Leather",
      id:"LEI076",
      },
     {
      image: require('../../assets/images/leather/lel77.jpg'),
      text:"Leather",
      id:"LEI077",
      },
     {
      image: require('../../assets/images/leather/lel78.jpg'),
      text:"Leather",
      id:"LEI078",
      },
     {
      image: require('../../assets/images/leather/lel79.jpg'),
      text:"Leather",
      id:"LEI079",
      },
     {
      image: require('../../assets/images/leather/lel80.jpg'),
      text:"Leather",
      id:"LEI080",
      },
     {
      image: require('../../assets/images/leather/lel81.jpg'),
      text:"Leather",
      id:"LEI081",
      },
     {
      image: require('../../assets/images/leather/lel82.jpg'),
      text:"Leather",
      id:"LEI082",
      },
     {
      image: require('../../assets/images/leather/lel83.jpg'),
      text:"Leather",
      id:"LEI083",
      },
     {
      image: require('../../assets/images/leather/lel84.jpg'),
      text:"Leather",
      id:"LEI084",
      },
     {
      image: require('../../assets/images/leather/lel85.jpg'),
      text:"Leather",
      id:"LEI085",
      },
     {
      image: require('../../assets/images/leather/lel86.jpg'),
      text:"Leather",
      id:"LEI086",
      },
     {
      image: require('../../assets/images/leather/lel87.jpg'),
      text:"Leather",
      id:"LEI087",
      },
     {
      image: require('../../assets/images/leather/lel88.jpg'),
      text:"Leather",
      id:"LEI088",
      },
     {
      image: require('../../assets/images/leather/lel89.jpg'),
      text:"Leather",
      id:"LEI089",
      },
     {
      image: require('../../assets/images/leather/lel90.jpg'),
      text:"Leather",
      id:"LEI090",
      },
     {
      image: require('../../assets/images/leather/lel91.jpg'),
      text:"Leather",
      id:"LEI091",
      },
     {
      image: require('../../assets/images/leather/lel92.jpg'),
      text:"Leather",
      id:"LEI092",
      },
     {
      image: require('../../assets/images/leather/lel93.jpg'),
      text:"Leather",
      id:"LEI093",
      },
     {
      image: require('../../assets/images/leather/lel94.jpg'),
      text:"Leather",
      id:"LEI094",
      },
     {
      image: require('../../assets/images/leather/lel95.jpg'),
      text:"Leather",
      id:"LEI095",
      },
     {
      image: require('../../assets/images/leather/lel96.jpg'),
      text:"Leather",
      id:"LEI096",
      },
     {
      image: require('../../assets/images/leather/lel97.jpg'),
      text:"Leather",
      id:"LEI097",
      },
     {
      image: require('../../assets/images/leather/lel98.jpg'),
      text:"Leather",
      id:"LEI098",
      },
     {
      image: require('../../assets/images/leather/lel99.jpg'),
      text:"Leather",
      id:"LEI099",
      },
     {
      image: require('../../assets/images/leather/lel100.jpg'),
      text:"Leather",
      id:"LEI100",
      },
     {
      image: require('../../assets/images/leather/lel101.jpg'),
      text:"Leather",
      id:"LEI101",
      },
     {
      image: require('../../assets/images/leather/lel102.jpg'),
      text:"Leather",
      id:"LEI102",
      },
     {
      image: require('../../assets/images/leather/lel103.jpg'),
      text:"Leather",
      id:"LEI103",
      },
     {
      image: require('../../assets/images/leather/lel104.jpg'),
      text:"Leather",
      id:"LEI104",
      },
     {
      image: require('../../assets/images/leather/lel105.jpg'),
      text:"Leather",
      id:"LEI105",
      },
     {
      image: require('../../assets/images/leather/lel106.jpg'),
      text:"Leather",
      id:"LEI106",
      },
     {
      image: require('../../assets/images/leather/lel107.jpg'),
      text:"Leather",
      id:"LEI107",
      },
     {
      image: require('../../assets/images/leather/lel108.jpg'),
      text:"Leather",
      id:"LEI108",
      },
     {
      image: require('../../assets/images/leather/lel109.jpg'),
      text:"Leather",
      id:"LEI109",
      },
     {
      image: require('../../assets/images/leather/lel110.jpg'),
      text:"Leather",
      id:"LEI110",
      },
     {
      image: require('../../assets/images/leather/lel111.jpg'),
      text:"Leather",
      id:"LEI111",
      },
     {
      image: require('../../assets/images/leather/lel112.jpg'),
      text:"Leather",
      id:"LEI112",
      },
     {
      image: require('../../assets/images/leather/lel113.jpg'),
      text:"Leather",
      id:"LEI113",
      },
     {
      image: require('../../assets/images/leather/lel114.jpg'),
      text:"Leather",
      id:"LEI114",
      },
     {
      image: require('../../assets/images/leather/lel115.jpg'),
      text:"Leather",
      id:"LEI115",
      },
     {
      image: require('../../assets/images/leather/lel116.jpg'),
      text:"Leather",
      id:"LEI116",
      },
     {
      image: require('../../assets/images/leather/lel117.jpg'),
      text:"Leather",
      id:"LEI117",
      },
     {
      image: require('../../assets/images/leather/lel118.jpg'),
      text:"Leather",
      id:"LEI118",
      },
     {
      image: require('../../assets/images/leather/lel119.jpg'),
      text:"Leather",
      id:"LEI119",
      },
     {
      image: require('../../assets/images/leather/lel120.jpg'),
      text:"Leather",
      id:"LEI120",
      },
     {
      image: require('../../assets/images/leather/lel121.jpg'),
      text:"Leather",
      id:"LEI121",
      },
     {
      image: require('../../assets/images/leather/lel122.jpg'),
      text:"Leather",
      id:"LEI122",
      },
     {
      image: require('../../assets/images/leather/lel123.jpg'),
      text:"Leather",
      id:"LEI123",
      },
     {
      image: require('../../assets/images/leather/lel124.jpg'),
      text:"Leather",
      id:"LEI124",
      },
     {
      image: require('../../assets/images/leather/lel125.jpg'),
      text:"Leather",
      id:"LEI125",
      },
     {
      image: require('../../assets/images/leather/lel126.jpg'),
      text:"Leather",
      id:"LEI126",
      },
     {
      image: require('../../assets/images/leather/lel127.jpg'),
      text:"Leather",
      id:"LEI127",
      },
     {
      image: require('../../assets/images/leather/fol1.jpg'),
      text:"Leather",
      id:"FOl001",
      },
     {
      image: require('../../assets/images/leather/fol2.jpg'),
      text:"Leather",
      id:"FOl002",
      },
     {
      image: require('../../assets/images/leather/fol3.jpg'),
      text:"Leather",
      id:"FOl003",
      },
     {
      image: require('../../assets/images/leather/fol4.jpg'),
      text:"Leather",
      id:"FOl004",
      },
     {
      image: require('../../assets/images/leather/fol5.jpg'),
      text:"Leather",
      id:"FOl005",
      },
     {
      image: require('../../assets/images/leather/fol6.jpg'),
      text:"Leather",
      id:"FOl006",
      },
     {
      image: require('../../assets/images/leather/fol7.jpg'),
      text:"Leather",
      id:"FOl007",
      },
     {
      image: require('../../assets/images/leather/fol8.jpg'),
      text:"Leather",
      id:"FOl008",
      },
     {
      image: require('../../assets/images/leather/fol9.jpg'),
      text:"Leather",
      id:"FOl009",
      },
     {
      image: require('../../assets/images/leather/fol10.jpg'),
      text:"Leather",
      id:"FOl0010",
      },
     {
      image: require('../../assets/images/leather/fol11.jpg'),
      text:"Leather",
      id:"FOl0011",
      },
     {
      image: require('../../assets/images/leather/fol12.jpg'),
      text:"Leather",
      id:"FOl0012",
      },
     {
      image: require('../../assets/images/leather/fol13.jpg'),
      text:"Leather",
      id:"FOl0013",
      },
     {
      image: require('../../assets/images/leather/fol14.jpg'),
      text:"Leather",
      id:"FOl0014",
      },
    //  {
    //   image: require('../../assets/images/leather/fol1.jpg'),
    //   text:"Leather",
    //   id:"FOl001",
    //   },
  ]

  const [currentFilter, setCurrentFilter] = useState(null);
  const [currentFilterData, setCurrentFilterData] = useState(productList);
  

  useEffect(() => {
      window.scrollTo({
          top: 0,
          left: 0,
        });
    setCurrentFilter(id)
      switch (id) {
      case 'All':
        setCurrentFilterData(productList)
        break;
      case 'Bags':
        setCurrentFilterData(bag)
        break;
      case 'Handicrafts':
        setCurrentFilterData(handicrafts)
        break;
      case 'Leather':
        setCurrentFilterData(leather)
        break;
      case 'Tabletop':
        setCurrentFilterData(tableTop)
        break;
      case 'Travel':
        setCurrentFilterData(travel)
        break;
      case 'Trophies & Medals':
        setCurrentFilterData(trophies)
        break;
      case 'Others':
        setCurrentFilterData(others)
        break;
      default:
        setCurrentFilterData(productList)
        break;
    }
  }, [id])

  const handleData = (item) => {
    setCurrentFilter(item)
    switch (item ) {
      case 'All':
        setCurrentFilterData(productList)
        break;
      case 'Bags':
        setCurrentFilterData(bag)
        break;
      case 'Handicrafts':
        setCurrentFilterData(handicrafts)
        break;
      case 'Leather':
        setCurrentFilterData(leather)
        break;
      case 'Tabletop':
        setCurrentFilterData(tableTop)
        break;
      case 'Travel':
        setCurrentFilterData(travel)
        break;
      case 'Trophies & Medals':
        setCurrentFilterData(trophies)
        break;
      case 'Others':
        setCurrentFilterData(others)
        break;
      case 'Caps':
        setCurrentFilterData(cap)
        break;
      case 'T-shirts':
        setCurrentFilterData(shirt)
        break;
      case 'Watches':
        setCurrentFilterData(watches)
        break;
      default:
        setCurrentFilterData(productList)
        break;
    }
  }

  return (
    <div className="py-10 max-w-screen-xl mx-auto">
      <div className="flex flex-wrap">
        {currentFilter && filterList.map(item => item === currentFilter ? <text className=" mx-2 mt-4 font-semibold text-gray-200 bg-gray-800 cursor-pointer px-6 py-2 rounded-sm">{item}</text> : <text onClick={() => handleData(item)} className="bg-gray-100 mx-2 mt-4 font-semibold text-gray-900 hover:text-gray-50 hover:bg-gray-700 cursor-pointer px-6 py-2 rounded-sm">{item}</text>)}
          {currentFilter === "All" ? <div className="grid grid-cols-4 gap-5 mt-10">
          {currentFilterData.map((item, index) => (
        <Link to="/contact">
        <div key={index} className=" ui-card cursor-pointer">
	<img className="w-full rounded-sm" src={item.image} alt="card"/>
	<div className="description flex-col justify-center items-center">
        <h3 className="text-xl font-semibold mt-2">{item.text}</h3>
        <h3 className="text-lg  mt-1">{item.category}</h3>
          <MdOutlineAdd className="p w-8 h-8 mt-4 font-extrabold mx-auto"/>
	</div>
              </div>
              </Link>
      ))}
          
    </div>: <div className="grid p-3 md:p-5 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-10">
            {currentFilter && currentFilterData.map((item, index) => (
        <Link to="/contact">
        <div key={index} className="border rounded-md ui-card cursor-pointer">
	<img className="w-full h-72" src={item.image} alt="card"/>
	<div className="description flex-col justify-center items-center">
        <h3 className="text-xl font-semibold mt-2">{item.text}</h3>
        <h3 className="text-lg  mt-1">Id - {item.id}</h3>
          <MdOutlineAdd className="p w-8 h-8 mt-4 font-extrabold mx-auto"/>
	</div>
                </div>
                </Link>
      ))}
          
    </div>}  

           </div>
      </div>
  )
}

export default ProductFilter