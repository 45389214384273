import React from 'react'
import {OrganizationDiv} from "../molecules";
import Testimonial from "./Testimonial";

import {useScreenContext} from '../../context/ScreenContext'


function AboutTabTwo() {
  const screen = useScreenContext()
  return (
    <div className="bg-gray-200">
    <div className="md:flex  justify-between max-w-screen-xl mx-auto md:py-10">
      <div className="md:w-6/12 p-3 ">
        <h6 className="flex text-2xl md:text-3xl text-gray-900 font-semibold">About Organization</h6>
<OrganizationDiv qus="Company Name" ans="BOMBAY GIFTS"/>
<OrganizationDiv qus="Year of Establishment" ans="2000"/>
<OrganizationDiv qus="Product Category" ans="30+"/>
<OrganizationDiv qus="Total No Of Products" ans="1500+"/>
<OrganizationDiv qus="Office Locations" ans="Mumbai, Chennai"/>
<OrganizationDiv qus="Country" ans="India"/>
      </div>
      <div className="md:w-6/12 md:mt-0 mt-5 p-3">
          <Testimonial screen={screen}/>
      </div>
     
      </div>
      </div>
  )
}

export default AboutTabTwo