import React,{useState} from 'react'
import {NavLink,Link} from "react-router-dom";

//icons
import {AiOutlineMenu, AiOutlineClose} from "react-icons/ai";

//images
import logo from '../../assets/images/logo.png'

function MobileHeader() {
  const [menu, setMenu] = useState(false)
  return (
    <div className="flex-col items-center justify-between py-6">
      <div className="flex justify-between items-center px-3">

        <img className="h-12" src={logo} alt="logo" />
        <AiOutlineMenu onClick={()=>setMenu(true)} className="h-12 w-12 p-2 text-gray-700"/>
      </div>
      {menu ? <div className="h-screen z-50 fixed top-0 bg-white w-full" >
        <div className="flex px-2 justify-between mt-4">
          <img className="h-12" src={logo} alt="logo" />
          <AiOutlineClose onClick={()=>setMenu(false)} className="h-12 w-12 p-2"/>
</div>
   
       <div onClick={()=>setMenu(false)}> <Nav  text="Home" link="/"/></div>
        <div onClick={()=>setMenu(false)}><Nav text="About Us" link="/about_us" />
        </div> 
        <div onClick={()=>setMenu(false)}><Nav text="Products" link="/products/All" />
        </div> 
        <div onClick={() => setMenu(false)}>
        <Nav text="Achievements" link="/achievements" />
        </div> 
        <div onClick={()=>setMenu(false)}><Nav text="Contact" link="/contact" />
        </div>  
       
      </div>: null} 
    </div>
  )
}

export default MobileHeader;

export const Nav = ({text,link}) => (
   <div className="my-4">
    <NavLink className="text-2xl mx-1 hover:border-b-2 border-gray-500 font-semibold text-gray-800" to={link}>{text}</NavLink>
        </div>
)