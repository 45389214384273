import React from 'react'
import {HomeTemplate} from "../templates";

function Home() {
  return (
    <div >
      <HomeTemplate />
    </div>
  )
}

export default Home