import React, {useState} from 'react'

//icons
import {BsTelephoneForward} from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

function BottomFooter() {
const [year, setYear] = useState(new Date())
  return (
    <div className="bg-gray-800">
      <div className=" lg:flex justify-between max-w-screen-xl mx-auto py-10 px-5">
        <text className="text-gray-300 text-left ">Copyright © {year.getFullYear()} All Rights Reserved.Site Design &nbs; Maintaining By Unibros Technologies</text>
         <div className="flex items-center justify-center lg:mt-0 mt-8">
     
          
    <a href="https://www.facebook.com/" target="_blank"> <FaFacebookF className="text-gray-300 mx-1 cursor-pointer hover:text-header_bg"/></a>
         <a href="https://www.linkedin.com/" target="_blank">  <FaLinkedinIn className="text-gray-300 mx-1 cursor-pointer hover:text-header_bg"/></a>
          
        </div>
      </div>
      </div>
  )
}

export default BottomFooter