import React from 'react'
import {HomeSlider, ProductCard, Testimonial} from "../organisms";

// import tw from 'twin.macro'

// const H6 =tw.h6`text-md text-left font-semibold mt-3 text-gray-800`

function HomeTemplate() {

  const productList = [
    {
      image: require('../../assets/images/thumb1.jpg'),
      text:"Bags",
      category:"Bag | Umbrella",
    },

    {
      image: require('../../assets/images/thumb2.jpg'),
      text:"Handicrafts",
      category:"Handicrafts",
    },
    {
      image: require('../../assets/images/thumb3.jpg'),
      text:"Leather",
      category:"Leather Items | Folders",
    },
    {
      image: require('../../assets/images/thumb4.jpg'),
      text:"Tabletop Displays",
      category:"Electronic Desktop | Wooden & Metal desktop | Watch & Clock | Electronic Goods" ,
    },
    {
      image: require('../../assets/images/thumb5.jpg'),
      text:"Travel",
      category:"Trolley suitcase | Jacket and Rain coat",
    },
    {
      image: require('../../assets/images/thumb6.jpg'),
      text:"Trophies & Medals",
      category:"Acrlic | Brass | Crystal | Iseries | Wooden | Medals",
    },
        {
      image: require('../../assets/images/thumb7.jpg'),
      text:"Others",
      category:"Gift Items | Badges | Diaries | Mugs | Pen & Keychains",
    },
  ]
  return (
    <div className="min-h-screen">
      <div className="bg-red-600 py-2  ">
        <div className="max-w-screen-xl mx-auto">
          <text className="text-white txt-6xl font-semibold">Gifting Solutions: Bag| Diaries & Pens|Caps |Mugs| T-Shirts | Umberlla | Leather Items | Tabletops | Handicrafts | Travel | Trophies &amp; Medals | More..</text>
        </div>
      </div>
      <div className="max-w-screen-xl py-5 mx-auto">
        <HomeSlider/>
      </div>
     <div className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto py-10 p-3">
          <h6 className="text-2xl md:text-3xl text-left font-semibold text-header_bg">Welcome to Bombay Gifts</h6>
                     <h6 className="text-md text-justify font-semibold mt-3 text-gray-800">We are the pioneer in the “Gifts & Novelties” industry in India and our presence more than 15+ years with 1000+ Satisfied Customers.</h6>
                     <h6 className="text-md text-justify  font-semibold mt-3 text-gray-800">We proud to say that, we won the Prestigious “Quality Brands India 2015-19” award and “National Industrial Excellence Award” from National Chamber of Commerce & Industry of India.</h6>
                     <h6 className="text-md text-justify  font-semibold mt-3 text-gray-800">We hold 1500+ products with 30+ different categories of gifting solutions. All our gifts are highly customized products based on the customer needs. Our Gifting solutions for the Corporate world are Bags, Caps, T-Shirts, Mugs, Diaries, Tabletop Displays, Leather Items & Folders, Trophies & Medals, Travel and Handicrafts Products.</h6>
                     <h6 className="text-md text-justify  font-semibold mt-3 text-gray-800">Explore our site for all your gifting solutions. We are happy to serve you better, reach us @ +91 44 42064964.</h6>
        </div>
        </div>
      <div className="max-w-screen-xl py-5 p-3 lg:py-10 mx-auto">
        <h6 className="text-2xl md:text-3xl text-left font-semibold">Our Products</h6>
        <ProductCard list={productList} />

      </div>
       <div className="bg-gray-200">
        <div className="max-w-screen-lg py-8 p-3 lg:py-20 mx-auto">
      <Testimonial />
          </div>
          </div>
    </div>
  )
}
export default HomeTemplate