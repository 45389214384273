import React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';


import { MdOutlineArrowBackIosNew,  MdOutlineArrowForwardIos} from 'react-icons/md';

function Testimonial({screen}) {
  return (
     <CarouselProvider
      naturalSlideWidth={screen >= 768 ? 500: 250}
        naturalSlideHeight={125}
        totalSlides={3}
      >
        <div className="flex justify-between items-center">
          <h6 className="flex text-2xl text-gray-900 font-semibold">Testimonials</h6>
          <div className="px-0 ">
             <ButtonBack className="mr-6 p-1 md:p-2 rounded-sm bg-gray-900 text-gray-100"><MdOutlineArrowBackIosNew/></ButtonBack>
        <ButtonNext className="ml-6 p-1 md:p-2 rounded-sm bg-gray-900 text-gray-100"><MdOutlineArrowForwardIos/></ButtonNext>
          </div>
        
        </div>
            
        <Slider className="rounded-md bg-white mt-3">
            <Slide className="" index={0}>
    <div className="lg:p-5 p-1">
            <h6 className=" text-sm lg:text-md text-left text-gray-900">“Bombay gifts Products are reasonable prices and Various Styles..They Accept our Customization on their products based on our Needs. Look forward to the next cooperation, and I am happy to recommend Bombay Gifts to others”</h6>
            <h6 className="text-right lg:mt-4 text-gray-900 font-semibold mr-6">– Divyabharathi, Vice President-HR, MNC, India.</h6>
     </div>
            </Slide>
            <Slide index={1}>
               <div className="p-2 lg:p-5">
                  <h6 className="text-md text-left text-gray-900">“We really appreciate their attentiveness to customer service and unique product ranges and prompt delivery”</h6>
            <h6 className="text-right lg:mt-4 text-gray-900 font-semibold mr-6">
– Pramod khairnar, Admin-Head, Telecommunication Sector, India. </h6>
     </div>
            </Slide>
            <Slide index={2}>
               <div className="lg:p-5 p-1">
                 <h6 className="text-md text-left text-gray-900">“All gifts are high quality in standards and its very suitable for prestigious Gifting Needs with Affordable prices. We feel really happy to associate with them”</h6>
                <h6 className="text-right lg:mt-4 text-gray-900 font-semibold mr-6">– Kuldeepsingh, Director, Manufacturing Sector, India.</h6>
                </div>
            </Slide>
        </Slider>
       
      </CarouselProvider>
  )
}

export default Testimonial