import React from 'react'


import {Text} from '../atoms'

function HeaderTextWithText({header_text, body_text}) {
  return (
    <div className="flex items-center">
      <Text Style="text-sm mx-2 font-semibold text-gray-300" text={`${header_text} :`}/>
      <Text Style="text-sm font-semibold text-gray-300" text={body_text}/>
    </div>
  )
}

export  {HeaderTextWithText}