import React from 'react'
import {ProductTemplate} from "../templates";

function Product() {
  return (
    <div>
      <ProductTemplate/>
    </div>
  )
}

export default Product