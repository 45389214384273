import React,{useEffect} from 'react'

//view
import {HeaderTextWithText} from "../molecules";

//icons
import {BsTelephoneForward} from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";

//images
import brand from '../../assets/images/brands.png'





function TopHeader() {
  
  return (
    <div className=" bg-gray-700 px-3 py-3">
      <div className="max-w-screen-xl flex items-center mx-auto justify-between">
      <div className="flex items-center">
        <div className="flex items-center mx-2">
        <BsTelephoneForward className="text-gray-300"/>
        <HeaderTextWithText header_text="Phone" body_text="+91 4442064964"/>
        </div>
        <div className="flex items-center mx-2">
        <BsTelephoneForward className="text-gray-300"/>
         <HeaderTextWithText header_text="Mail" body_text="mukesh@bombaygifts.net"/>
        </div>
      </div>
      <div className="flex items-center justify-between w-3/12">
          <img className="h-14 w-14" src={brand} alt="icon"/>
     
          <div className="mx-2 flex">
    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"> <FaFacebookF className="text-gray-300 mx-1 cursor-pointer hover:text-header_bg"/></a>
         <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">  <FaLinkedinIn className="text-gray-300 mx-1 cursor-pointer hover:text-header_bg"/></a>
          </div>
        </div>
        </div>
    </div>
  )
}

export default TopHeader