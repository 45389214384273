import logo from './logo.svg';
import './App.css';

import {
  Routes,
  Route,
  Link
} from "react-router-dom";

//components
import {BottomFooter, MobileHeader, TopHeader} from './view/organisms'
import {Header} from './view/organisms'
import {Footer} from "./view/organisms";

//context
import {useScreenContext} from './context/ScreenContext'

//pages
import {Home, ContactUs, Achievements, AboutUs, Product} from "./view";

function App() {
  const state = useScreenContext();
  console.log(state);
  return (
    <div className="App">
      {state >= 768 ? <TopHeader /> : null} 
      {state > 768 ? <Header /> : <MobileHeader />} 
      
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/contact" element={<ContactUs/>}/>
        <Route path="/achievements" element={<Achievements/>}/>
        <Route path="/about_us" element={<AboutUs/>}/>
        <Route path="/products/:id" element={<Product/>}/>
      </Routes>
      <Footer />
      <BottomFooter/>
    </div>
  );
}

export default App;
