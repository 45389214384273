import React from 'react';
import {InputText,Text} from "../atoms";
import {InputWithText} from "../molecules";
import emailjs from '@emailjs/browser';


function ContactForm({divStyle}) {
  const form = React.useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_x7wqaei', 'template_qly6kmo', form.current, 'user_BZhRna8AIoWD2jPAVd4g0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
  };

  return<form ref={form} onSubmit={sendEmail} className={`${divStyle}`}>
    <div className={`lg:mx-0 mx-5 flex-col justify-center w-full`}>
      <h6 className="text-3xl text-left py-3 font-semibold">Enquiry</h6>
    <InputWithText type="text" name="name" text="Name" placeholder="Name" />
    <InputWithText type="email" name="email" text="Email" placeholder="Email" />
    <InputWithText type="text" name="subject" text="Subject" placeholder="Subject" />
  
      <div className="flex-col lg:w-96">
        <div className="flex">
      <Text Style="font-semibold" text="Message"/>
        </div>
      <textarea name="message" className=" border-1 outline-none my-1 border  w-full border-gray-300 px-2 rounded-md h-24 text-gray-700 font-normal p-1" placeholder="Your Message" />
      </div>
  </div>

    <div className="flex justify-end pr-5 lg:pr-0  md:mr-0 md:mt-10 pb-4">
      <input className="bg-primary py-2  px-4 rounded-md bg-header_bg  text-white  font-nunito_Sans cursor-pointer" type="submit" value="SEND" />
    </div>
  </form>
   
}

export {ContactForm};
