import React from 'react'
import '../card.css'
import { AiOutlineTrophy } from "react-icons/ai";

function ImageHoverCard({image, text, category}) {
  return (
      <div className=" ui-card cursor-pointer">
	<img className="" src={image} alt="card"/>
	<div className="description flex-col justify-center items-center">
        <h3 className="text-xl font-semibold">{text}</h3>
        <h3 className="text-xl font-semibold">{category}</h3>
          <AiOutlineTrophy className="p mx-auto"/>
	</div>
</div>

  )
}

export default ImageHoverCard