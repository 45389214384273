import React from 'react'
import '../card.css'
import { MdOutlineAdd } from "react-icons/md";
import {Link} from "react-router-dom";

function ProductCard({list}) {
  return (
    <div className="grid md:grid-cols-3 gap-5 mt-3">
      {list.map(item => (
        <Link to={`/products/${item.text}`}>
             <div className=" ui-card cursor-pointer">
	<img className="w-full rounded-sm" src={item.image} alt="card"/>
	<div className="description flex-col justify-center items-center">
        <h3 className="text-xl font-semibold mt-2">{item.text}</h3>
        <h3 className="text-lg  mt-1">{item.category}</h3>
          <MdOutlineAdd className="p w-8 h-8 mt-4 font-extrabold mx-auto"/>
	</div>
</div>
        </Link>
     
      ))}
          
    </div>
  )
}

export default ProductCard