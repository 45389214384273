import React from 'react';

function InputText({inputStyle, text, placeholder, divStyle, name, type}) {
  console.log(type);
  return <div className="border-1 border-gray-500 my-2 rounded-md ">
    <input name={name} type={type} className={inputStyle} placeholder={placeholder}/>
  </div>
}

export {InputText};
