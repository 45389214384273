import React from 'react'
import { RiOrganizationChart} from 'react-icons/ri';

function OrganizationDiv({qus,ans}) {
  return (
            <div className="flex items-center mt-2">
      <RiOrganizationChart className="text-header_bg text-lg" />
    <h6 className="text-gray-900 text-left w-4/12 md:3/12 cursor-pointer hover:text-header_bg truncate ml-4 text-md">{qus}</h6>
    <h6 className="text-gray-900 text-left w-4/12 md:3/12 cursor-pointer hover:text-header_bg truncate ml-4 text-md">: &nbsp;{ans}</h6>
    </div>
  )
}

export default OrganizationDiv