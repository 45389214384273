import React, {createContext, useContext, useState,useEffect} from 'react';

export const ScreenContext = createContext(300);
export const useScreenContext = () => useContext(ScreenContext);

function ScreenContextProvider({children}) {
  const [width, setWidth] = useState('');

  const screenSize = (e) => {
    console.log(document.documentElement.clientWidth);
    setWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    console.log(window.innerWidth);
    setWidth(window.innerWidth)
  }, [])
  useEffect(() => {
    window.addEventListener('resize', screenSize);
  }, [width])

  return (
    <ScreenContext.Provider value={width}>
      {children}
   </ScreenContext.Provider>
  )
}

export default ScreenContextProvider;